import React, { useState, useEffect } from 'react';
// MUI
import LoadingButton from '@mui/lab/LoadingButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
// コンポーネント
import { SubTitle } from 'components/uiParts/SubTitle';
import { BorderTop } from 'components/uiParts/BorderTop';
import { CustomDatePicker } from 'components/uiParts/CustomDatePicker';
import { ProcessingSheetModal } from 'components/uiParts/Modal/ProcessingSheetModal';
import { EditDataTable } from 'components/uiParts/DataGrid/EditDataTable';
import { StockListModal } from 'components/uiParts/Modal/StockListModal';
import { SelectStocksConfModal } from 'components/uiParts/Modal/SelectStocksConfModal';
import { SelectStockModal } from 'components/uiParts/Modal/SelectStockModal';
import { SubOrderListModal } from 'components/uiParts/Modal/SubOrderListModal';
import { SelectProductModal } from 'components/uiParts/Modal/SelectProductModal';
import { Breadcrumb } from 'components/uiParts/Breadcrumb';
// 定数
import {
  PRODUCT_TABLE_MODAL_COLUMNS,
  SUB_ORDER_TABLE_COLUMNS_FOR_ORDER,
} from 'utils/constantsTable';
// カスタムフック
import { useGetDetailId } from 'hooks/useGetDetailId';
// import { useDeleteForm } from 'hooks/useForm';
import { useUpdateOrderForm } from 'hooks/useOrderForm';

// URLを取得
const url = new URL(window.location.href);
// URLSearchParamsオブジェクトを取得
const params = url.searchParams;
// getメソッドSubOrderIdを取得 在庫選択モーダルを表示する
const getSubOrderId = params.get('SubOrderId');

export const UpdateOrderForm = () => {
  const [isOpenFromQRCode, setIsOpenFromQRCode] = useState(false);
  const [isViewStockSelectList, setIsViewStockSelectList] = useState(false);
  const [customDataGridStyle, setCustomDataGridStyle] = useState({});
  const [getRows, setGetRows] = useState([]);
  const [isDisableFlag, setIsDisableFlag] = useState(true);

  // カスタムフック
  const { id } = useGetDetailId();
  const {
    addLoadingForm,
    handleClickProcessingSheet,
    printProcessingSheet,
    pdfData,
    openProcessingSheet,
    setOpenProcessingSheet,
    handleClickUpdateOrderForm,
    deleteIds,
    setDeleteIds,
    emptyRows,
    editRows,
    setEditRows,
    isPageReload,
    setIsPageReload,
    reloadPage,
    clientInfo,
    setClientInfo,
    selectedOrderDate,
    setSelectedOrderDate,
    selectedOrderMoney,
    setSelectedOrderMoney,
    setSelectedDeliveryDate,
    subOrderData,
    getSubOrderFromQRCode,
    handleClickStartProcessingBtn,
    openSubOrderListModal,
    setOpenSubOrderListModal,
    handleClickProcessingAndStockBtn,
    selectedSubOrderData,
    setSubOrderGRows,
    editModalRows,
    setEditModalRows,
    setSubOrderId,
    formInputValue,
    formInputNewValue,
    addLoadingUpdateForm,
    openSelectStockModal,
    totalLength,
    orderLength,
    setOpenSelectStockModal,
    handleChangeUpdateForm,
    handleClickUpdateRows,
    handleClickSelectStock,
    handleCloseStockModal,
    isCloseModal,
    setIsCloseModal,
    isLoading,
    selectedSubOrderRows,
    setSelectedSubOrderRows,
    openSelectStocksConfModal,
    setOpenSelectStocksConfModal,
    selectedStockId,
    resetSelectedRowIds,
    setResetSelectedRowIds,
    setSelectedSubOrderData,
    getStockSelect,
    handleClickDeleteStock,
    isStockListModalFormValid,
    handleCloseStockListModal,
    openStockModal,
    setOpenStockModal,
    selectedSubOrderLength,
    handleClickOpenSelectStocks,
    handleClickOpenSelectStock,
    isAllStockBtn,
    isClickSelectStocksBtn,
    selectedStockIds,
    openProductModal,
    selectedProductId,
    setSelectedProductId,
    setOpenProductModal,
    handleClickSelectProduct,
    addProductRows,
    setAddProductRows,
    selectedProductRow,
    setSelectedProductRow,
    subOrderGRows,
    selectedSubOrderDeliveryDate,
    setSelectedSubOrderDeliveryDate,
    isFormValidation,
    setIsFormValidation,
    cellEditStart,
    isStockSelectLoading,
    handleClickPrintShipSheet,
    handleClickProcessingBulk,
    isClickOpenSelectStocks,
    changeSubOrderStatus,
    updateDatas,
  } = useUpdateOrderForm();

  // getパラメーターがあるときは在庫のモーダルを表示する
  useEffect(() => {
    if (getSubOrderId === null) return;
    setSubOrderId(getSubOrderId);
    setOpenStockModal(true);
    setIsOpenFromQRCode(true);
    getSubOrderFromQRCode(getSubOrderId);
  }, [getSubOrderId]);

  // OpenSubOrderIdパラメーターがあるときは注文詳細モーダルを表示する
  useEffect(() => {
    if (new URL(window.location.href).searchParams.get('OpenSubOrderId') === null) return;
    const copyGetRows = JSON.parse(JSON.stringify(getRows)); // コピー
    const idx = copyGetRows.findIndex(
      (d) => String(d.id) === new URL(window.location.href).searchParams.get('OpenSubOrderId')
    ); // 該当データのindexを取得
    if (idx >= 0) {
      setOpenSubOrderListModal(true); // モーダル開く
      setSelectedSubOrderData({ row: copyGetRows[idx] }); // モーダルで開くrowのオブジェクトを入れる
    }
  }, [getRows]);

  // th横に必須マークをつける
  useEffect(() => {
    if (!SUB_ORDER_TABLE_COLUMNS_FOR_ORDER.length) return;
    let styleObject = {};
    for (let i = 0; i < SUB_ORDER_TABLE_COLUMNS_FOR_ORDER.length; i++) {
      if (
        SUB_ORDER_TABLE_COLUMNS_FOR_ORDER[i].editable &&
        SUB_ORDER_TABLE_COLUMNS_FOR_ORDER[i].reauired
      ) {
        const p =
          '& .MuiDataGrid-columnHeader[data-field="' +
          SUB_ORDER_TABLE_COLUMNS_FOR_ORDER[i].field +
          '"]:after';
        styleObject = {
          ...styleObject,
          ...{
            [p]: {
              color: 'red',
              content: '"※"',
            },
          },
        };
      } else {
        const p =
          '& .MuiDataGrid-columnHeader[data-field="' +
          SUB_ORDER_TABLE_COLUMNS_FOR_ORDER[i].field +
          '"]:after';
        styleObject = {
          ...styleObject,
          ...{
            [p]: {
              content: 'none',
            },
          },
        };
      }
    }

    setCustomDataGridStyle(styleObject); // スタイル変更を反映
  }, [SUB_ORDER_TABLE_COLUMNS_FOR_ORDER]);

  const breadcrumbList = [
    { name: 'ダッシュボード', href: '/' },
    { name: '受注一覧', href: '/order/list' },
    { name: '受注詳細' },
  ];

  const updateHandleOpen = () => {
    if (setSelectedProductRow) {
      setSelectedProductRow({}); // リセット
    }

    setOpenProductModal(true);
  };

  return (
    <>
      <Breadcrumb breadcrumbList={breadcrumbList} />
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <SubTitle title='顧客情報' />
        <Button href={location.origin + '/order/list'} variant='contained' color='inherit'>
          戻る
        </Button>
      </div>
      <Table
        sx={{ background: '#EBEBEB', margin: '20px 0 0', width: 'auto' }}
        size='small'
        aria-label='a dense table'
      >
        <TableBody>
          <TableRow sx={{ border: 0 }}>
            <TableCell sx={{ width: '100px', border: 0 }}>顧客ID</TableCell>
            <TableCell sx={{ border: 0, paddingLeft: 0 }}>
              {clientInfo.id && clientInfo.id}
            </TableCell>
          </TableRow>
          <TableRow sx={{ border: 0 }}>
            <TableCell sx={{ width: '100px', border: 0 }}>顧客名</TableCell>
            <TableCell sx={{ border: 0, paddingLeft: 0 }}>
              {clientInfo.name && clientInfo.name}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <BorderTop />
      <Table sx={{ margin: '20px 0 0' }} size='small' aria-label='a dense table'>
        <TableBody>
          <TableRow sx={{ border: 0 }}>
            <TableCell sx={{ width: '80px', border: 0, paddingLeft: 0 }}>受注日</TableCell>
            <TableCell sx={{ border: 0, paddingLeft: 0 }}>
              <div style={{ width: '200px' }}>
                <CustomDatePicker
                  date={selectedOrderDate}
                  setDate={setSelectedOrderDate}
                  setIsDisableFlag={setIsDisableFlag}
                />
              </div>
            </TableCell>
          </TableRow>
          <TableRow sx={{ border: 0 }}>
            <TableCell sx={{ border: 0, paddingLeft: 0 }}>受注金額</TableCell>
            <TableCell sx={{ border: 0, padding: '16px 0', fontSize: '26px' }}>
              {'¥' + Number(selectedOrderMoney).toLocaleString()}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <BorderTop />
      <SubTitle title='注文商品' hideBorder={true} />
      <div style={{ position: 'relative' }}>
        {/* 商品選択のモーダル */}
        <div>
          <SelectProductModal
            open={openProductModal}
            setOpen={setOpenProductModal}
            handleClickSelectProduct={handleClickSelectProduct}
            isButton={false}
            setSelectedProductRow={setSelectedProductRow}
            columns={PRODUCT_TABLE_MODAL_COLUMNS}
            isSelectProductButton={false}
          />
        </div>
        <EditDataTable
          update
          title=''
          vh='auto'
          emptyRows={emptyRows}
          editRows={editRows}
          setEditRows={setEditRows}
          columns={SUB_ORDER_TABLE_COLUMNS_FOR_ORDER}
          api='SubOrderForUser'
          params={[{ film_orders_master_id: id }]}
          isDeleteBtn={false}
          clientInfo={clientInfo}
          checkboxSelection={true}
          setClientInfo={setClientInfo}
          selectedOrderDate={selectedOrderDate}
          setSelectedOrderMoney={setSelectedOrderMoney}
          setSelectedDeliveryDate={setSelectedDeliveryDate}
          setSelectedOrderDate={setSelectedOrderDate}
          isMIOutputBtn
          isPSelectOutputBtn
          handleClickProcessingSheet={handleClickProcessingSheet}
          handleClickOpenSelectStock={handleClickOpenSelectStock}
          deleteIds={deleteIds}
          setDeleteIds={setDeleteIds}
          isPageReload={isPageReload}
          handleClickStartProcessingBtn={handleClickStartProcessingBtn}
          handleClickProcessingAndStockBtn={handleClickProcessingAndStockBtn}
          isProcessingAndStockBtn
          isSortSubOrderGId
          setSubOrderGRows={setSubOrderGRows}
          getRows={setGetRows}
          selectedProductId={selectedProductId}
          setSelectedProductId={setSelectedProductId}
          addProductRows={addProductRows}
          setAddProductRows={setAddProductRows}
          selectedProductRow={selectedProductRow}
          setSelectedProductRow={setSelectedProductRow}
          rowSelectable={(params) => String(params.row.id).split('_')[0] !== 'add'} // idの先頭にaddがある場合は登録前のレコードなのでチェックボックスは無効
          subOrderGRows={subOrderGRows}
          selectedSubOrderDeliveryDate={selectedSubOrderDeliveryDate}
          setSelectedSubOrderDeliveryDate={setSelectedSubOrderDeliveryDate}
          setIsFormValidation={setIsFormValidation}
          customDataGridStyle={customDataGridStyle}
          cellEditStart={cellEditStart}
          handleClickPrintShipSheet={handleClickPrintShipSheet}
          isDeleteBtnOrderDetail={true}
          updateHandleOpen={updateHandleOpen}
          updateProcessingBtn
          handleClickProcessingBulk={handleClickProcessingBulk}
          updateShippedBtn
          changeSubOrderStatus={changeSubOrderStatus}
          updateDatas={updateDatas}
        />
      </div>
      <div style={{ height: '50px' }} />
      <BorderTop />
      <div style={{ textAlign: 'right' }}>
        <LoadingButton
          disabled={
            ((editRows.length === 0 && isDisableFlag) || isFormValidation) && deleteIds.length === 0
          }
          onClick={() => handleClickUpdateOrderForm(false)}
          variant='contained'
          loading={addLoadingForm}
        >
          注文更新
        </LoadingButton>
      </div>
      {/* 加工指示書のモーダル */}
      <ProcessingSheetModal
        open={openProcessingSheet}
        setOpen={setOpenProcessingSheet}
        pdfDatas={pdfData}
        printProcessingSheet={printProcessingSheet}
      />
      {/* 在庫リストのモーダル */}
      <StockListModal
        open={openStockModal}
        isViewStockSelectList={isViewStockSelectList}
        subOrderData={subOrderData}
        reloadPage={reloadPage}
        isOpenFromQRCode={isOpenFromQRCode}
        setIsViewStockSelectList={setIsViewStockSelectList}
        formInputValue={formInputValue}
        formInputNewValue={formInputNewValue}
        addLoadingUpdateForm={addLoadingUpdateForm}
        totalLength={totalLength}
        orderLength={orderLength}
        setOpenSelectStockModal={setOpenSelectStockModal}
        handleChangeUpdateForm={handleChangeUpdateForm}
        handleClickUpdateRows={handleClickUpdateRows}
        isCloseModal={isCloseModal}
        setIsCloseModal={setIsCloseModal}
        isPageReload={isPageReload}
        isLoading={isLoading}
        handleClickDeleteStock={handleClickDeleteStock}
        isStockListModalFormValid={isStockListModalFormValid}
        handleClose={handleCloseStockListModal}
        isStockSelectLoading={isStockSelectLoading}
        handleClickStartProcessingBtn={handleClickStartProcessingBtn}
      />
      {/* 一括在庫在庫の確認モーダル */}
      <SelectStocksConfModal
        open={openSelectStocksConfModal}
        setOpen={setOpenSelectStocksConfModal}
        handleClickUpdateRows={handleClickUpdateRows}
        selectedStockId={selectedStockId}
        addLoadingForm={addLoadingForm}
      />
      {/* 在庫選択のモーダル */}
      <SelectStockModal
        open={openSelectStockModal}
        subOrderData={subOrderData}
        handleClickSelectStock={handleClickSelectStock}
        handleClose={handleCloseStockModal}
        isAllStockBtn={isAllStockBtn}
        selectedSubOrderLength={selectedSubOrderLength}
        isClickSelectStocksBtn={isClickSelectStocksBtn}
        selectedStockIds={selectedStockIds}
        isClickOpenSelectStocks={isClickOpenSelectStocks}
      />
      {/* 注文一覧のモーダル */}
      <SubOrderListModal
        id={id}
        openSubOrderListModal={openSubOrderListModal}
        setOpenSubOrderListModal={setOpenSubOrderListModal}
        openStockModal={openStockModal}
        setOpenStockModal={setOpenStockModal}
        handleClickProcessingSheet={handleClickProcessingSheet}
        deleteIds={deleteIds}
        setDeleteIds={setDeleteIds}
        emptyRows={emptyRows}
        editRows={editRows}
        isPageReload={isPageReload}
        clientInfo={clientInfo}
        setClientInfo={setClientInfo}
        selectedOrderDate={selectedOrderDate}
        setSelectedOrderDate={setSelectedOrderDate}
        setSelectedOrderMoney={setSelectedOrderMoney}
        setSelectedDeliveryDate={setSelectedDeliveryDate}
        handleClickStartProcessingBtn={handleClickStartProcessingBtn}
        selectedSubOrderData={selectedSubOrderData}
        handleClickUpdateOrderForm={handleClickUpdateOrderForm}
        addLoadingForm={addLoadingForm}
        editModalRows={editModalRows}
        setEditModalRows={setEditModalRows}
        setIsViewStockSelectList={setIsViewStockSelectList}
        handleClickOpenSelectStock={handleClickOpenSelectStock}
        handleClickOpenSelectStocks={handleClickOpenSelectStocks}
        setSelectedSubOrderRows={setSelectedSubOrderRows}
        selectedSubOrderLength={selectedSubOrderLength}
        resetSelectedRowIds={resetSelectedRowIds}
        handleClickProcessingBulk={handleClickProcessingBulk}
      />
    </>
  );
};
