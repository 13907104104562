import React, { useCallback, useState, useEffect } from 'react';
import _, { set } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
// カスタムフック
import { useSnackBar } from 'hooks/useSnackBar';
import { useCommonFunction } from 'hooks/useCommonFunction';
import { useDeleteForm } from 'hooks/useForm';
import { useAddOrUpdateData } from 'hooks/useAddOrUpdateData';
import { useGetOtherSetting } from 'hooks/useGetOtherSetting';
import { useSelectProductModal } from 'hooks/useSelectProductModal';
// 定数
import { SUB_ORDER_STATUS_LIST } from 'utils/constantsTable';
import { SUB_ORDER_UPDATE_INPUT } from 'utils/constantsFormInput';

/**
 * 値チェック
 * value1とvalue2は、仕様変更により意味が同じでもカラム名が異なるものがあるため二つのパターンを用意する
 * @param {String or Number} defaultValue 値が無いときに入れる
 * @param {String or Number} value1 値1
 * @param {String or Number} value2 値2
 * @returns
 */
const checkColumn = (defaultValue, value1, value2) => {
  let value = defaultValue;
  if (value1 !== undefined) {
    value = value1;
  }
  if (value2 !== undefined) {
    value = value2;
  }
  return value;
};

/**
 * 登録フォームを使う際のカスタムフック
 * @param {String} getApi 登録API名
 */
export const useCreateOrderForm = () => {
  const [clientInfo, setClientInfo] = useState({});
  const [openClientModal, setOpenClientModal] = useState(false);
  const [selectedOrderMoney, setSelectedOrderMoney] = useState(0);
  const [selectedDeliveryDate, setSelectedDeliveryDate] = useState('');
  const { useGetToday } = useCommonFunction();
  const [isFormValidation, setIsFormValidation] = useState(true);
  const emptyRows = [
    {
      id: '0',
      film_products_master_id: null,
      name: '',
      order_process: '開反',
      thickness: null,
      width: null,
      length: null,
      specific_gravity: null,
      order_number: 1,
      cost_price: null,
      unit_price: null,
      processing_price_value: null,
      sub_total: null,
      amount_money: null,
      SellingPriceM1: null,
      ProcessingCost: null,
      order_date: useGetToday(),
      delivery_date: '',
      ProcessingMethod: '',
      unit: '枚',
      outsourcing: 'しない',
      remarks: '',
      weight: null, // 重さ
      total_weight: null, // 合計重量
    },
  ];
  const [editRows, setEditRows] = useState(emptyRows);
  const { toggleSnack } = useSnackBar();
  const { otherSettingList } = useGetOtherSetting();
  const [selectedOrderDate, setSelectedOrderDate] = useState(useGetToday);
  const [selectedSubOrderDeliveryDate, setSelectedSubOrderDeliveryDate] = useState('');

  const {
    openProductModal,
    selectedProductId,
    selectedProductRow,
    setSelectedProductId,
    setOpenProductModal,
    handleClickSelectProduct,
    setSelectedProductRow,
  } = useSelectProductModal();

  /** 顧客を選択する */
  const handleClickSelectClient = useCallback((params) => {
    setClientInfo({
      id: params.row.id,
      name: params.row.name,
      salesPriceCategoryId: params.row.film_sales_price_categories_master_id,
      processingPriceCategoryId: params.row.film_processing_price_categories_master_id,
      titleOfHonor: params.row.title_of_honor,
    });
    setOpenClientModal(false);
  }, []);

  const [addLoadingForm, setAddLoadingForm] = useState(false);

  /** 登録処理 */
  const handleClickCreateForm = async (isDetailPage, inputSubOrder, clientName) => {
    setAddLoadingForm(true); // ローディング表示

    const redirectUrl = '/order/list';

    // input用にオブジェクトを作成
    let input = [
      {
        film_clients_master_id: clientInfo.id,
        order_date: selectedOrderDate,
        delivery_date: selectedDeliveryDate,
        amount_money: selectedOrderMoney,
      },
    ];

    // 受注と注文を登録
    const url = `${process.env.REACT_APP_API_URL}/FILMa_AddOrderAndSubOrder?code=${process.env.REACT_APP_API_CODE}`;

    // 注文を登録
    const url2 = `${process.env.REACT_APP_API_URL}/FILMa_AddSubOrder?code=${process.env.REACT_APP_API_CODE}`;

    let status = 'success';
    let message = '登録が完了しました';

    /**
     * 注文登録
     */
    const createSubOrder = async (datas, orderId) => {
      const input2 = [];
      const copyEditRows = JSON.parse(JSON.stringify(datas)); // コピー

      for (let i = 0; i < copyEditRows.length; i++) {
        // film_products_master_idが無いデータは追加しない
        if (copyEditRows[i].film_products_master_id === null) {
          continue;
        }

        // 注文金額は注文数で割る
        let amountMoney =
          copyEditRows[i].amount_money !== undefined ? copyEditRows[i].amount_money : 0;
        const num = copyEditRows[i].order_number !== undefined ? copyEditRows[i].order_number : 1;
        amountMoney = amountMoney;

        let newOrderId = '';
        if (orderId) {
          // 登録ページからの登録処理
          newOrderId = orderId;
        } else {
          // 詳細ページからの登録処理
          newOrderId = checkColumn('', copyEditRows[i].film_orders_master_id);
        }

        const object = {
          film_orders_master_id: newOrderId, // 受注ID
          sub_order_group_id: '', // 注文グループID
          order_thickness: checkColumn(null, copyEditRows[i].thickness, copyEditRows[i].order_thickness), // 注文/厚み
          order_width: checkColumn(null, copyEditRows[i].width, copyEditRows[i].order_width), // 注文/幅
          order_length: checkColumn(null, copyEditRows[i].length, copyEditRows[i].order_length), // 注文/長さ
          order_process: checkColumn('', copyEditRows[i].order_process), // 注文/加工方法
          order_number: num, // 注文数
          order_date: checkColumn(null, copyEditRows[i].order_date), // 受注日
          delivery_date: checkColumn(null, copyEditRows[i].delivery_date), // 納品日
          unit_price: checkColumn(0, copyEditRows[i].unit_price), // 単価
          cost_price: checkColumn(0, copyEditRows[i].cost_price), // 原価
          processing_price_value: checkColumn(0, copyEditRows[i].processing_price_value), // 加工費
          sub_total: checkColumn(0, copyEditRows[i].sub_total), // 小計
          amount_money: amountMoney, // 注文金額
          order_house_name: checkColumn('', copyEditRows[i].order_house_name), // ハウス名
          order_remark: checkColumn('', copyEditRows[i].order_remark), // 受注/備考
          sub_order_remark: '', // 注文/備考
          status: checkColumn('', copyEditRows[i].outsourcing) === 'しない' ? '新規' : '加工済', // 登録時は外注が'しない'で'新規'、'する'で'加工済'
          processing_client_name: checkColumn('', clientInfo.name, clientName), // 加工/顧客名
          processing_title_of_honor: checkColumn('', clientInfo.titleOfHonor),
          film_products_master_id: checkColumn('', copyEditRows[i].film_products_master_id), // 加工/商品ＩＤ
          processing_product_name: checkColumn('', copyEditRows[i].name, copyEditRows[i].processing_product_name), // 加工/商品名
          process_date: null, // 加工日
          ship_date: null, // 出荷日
          consumption_tax: checkColumn(null, otherSettingList[0].consumption_tax), // 消費税率
          unit: checkColumn('枚', copyEditRows[i].unit), // 単位
          weight: checkColumn(0, copyEditRows[i].weight), // 重さ
          total_weight: checkColumn(0, copyEditRows[i].total_weight), // 合計重量
          outsourcing: checkColumn('', copyEditRows[i].outsourcing), // 外注
        };

        // https://www.npmjs.com/package/uuid
        // ユニークなidを生成する
        let uuid = uuidv4();

        // 注文数分追加する
        for (let c = 1; c <= object.order_number; c++) {
          // 受注ID_uuid_注文カウント
          input2.push({
            ...object,
            ...{
              sub_order_group_id: String(object.film_orders_master_id + '_' + uuid + '_' + c),
            },
          });
        }
      }

      // 詳細ページから
      // 注文のみ追加
      if (isDetailPage) {
        let response2 = await fetch(url2, {
          method: 'POST',
          body: JSON.stringify(input2),
        })
          .then((response) => {
            setAddLoadingForm(false); // ローディング非表示
            return response.json();
          })
          .catch((error) => {
            setAddLoadingForm(false); // ローディング非表示
            return false;
          });

        if (response2 === false || response2.code) {
          // 失敗時
          status = 'error';
          message = '登録に失敗しました' + ' FILMa_AddSubOrder';
        } else {
          // 成功時
          window.location.replace(location.origin + redirectUrl); // 一覧へリダイレクト
        }

        if (response2.code) {
          message = message + '[' + response2.code + ']';
        }
      } else {
        let response = await fetch(url, {
          method: 'POST',
          body: JSON.stringify({
            'input': input,
            'input2': input2,
          }),
        })
          .then((response) => {
            setAddLoadingForm(false); // ローディング非表示
            return response.json();
          })
          .catch((error) => {
            setAddLoadingForm(false); // ローディング非表示
            return false;
          });

        if (response === false || response.code) {
          // 失敗時
          status = 'error';
          message = '登録に失敗しました' + ' FILMa_AddOrderAndSubOrder';
        } else {
          // 成功時
          window.location.replace(location.origin + redirectUrl); // 一覧へリダイレクト
        }

        if (response.code) {
          message = message + '[' + response.code + ']';
        }
      }

    };

    if (isDetailPage) {
      // 詳細ページからの登録処理
      await createSubOrder(inputSubOrder);
    } else {
      // 登録ページからの登録処理
      await createSubOrder(editRows, null);
    }

    toggleSnack(true, status, message);
  };

  return {
    openClientModal,
    openProductModal,
    selectedProductId,
    addLoadingForm,
    clientInfo,
    selectedOrderDate,
    selectedOrderMoney,
    emptyRows,
    editRows,
    selectedProductRow,
    setSelectedProductId,
    setOpenProductModal,
    setOpenClientModal,
    setEditRows,
    handleClickSelectClient,
    handleClickSelectProduct,
    handleClickCreateForm,
    setSelectedOrderDate,
    setSelectedOrderMoney,
    setSelectedDeliveryDate,
    setSelectedProductRow,
    selectedSubOrderDeliveryDate,
    setSelectedSubOrderDeliveryDate,
    isFormValidation,
    setIsFormValidation,
  };
};

/**
 * 更新フォームを使う際のカスタムフック
 */
export const useUpdateOrderForm = () => {
  const [formInputValue, setFormInputValue] = useState([]); // データ取得後のデータ
  const [formInputNewValue, setFormInputNewValue] = useState([]); // 新規登録するデータ
  const [isLoading, setIsLoading] = useState(true);
  const [addLoadingUpdateForm, setAddLoadingUpdateForm] = useState(false);
  const [errors, setErrors] = useState({});
  const [openSubOrderListModal, setOpenSubOrderListModal] = useState(false);
  const [openSelectStockModal, setOpenSelectStockModal] = useState(false);
  const [totalLength, setTotalLength] = useState(0);
  const [orderLength, setOrderLength] = useState(0);
  const [pdfData, setPdfData] = useState([]);
  const [openProcessingSheet, setOpenProcessingSheet] = useState(false);
  const [selectedSubOrderDeliveryDate, setSelectedSubOrderDeliveryDate] = useState('');
  const [isFormValidation, setIsFormValidation] = useState(true);
  const emptyRows = [
    {
      id: '0',
      film_products_master_id: null, // 商品ID
      processing_product_name: '', // 商品名
      order_process: '開反', // 加工方法
      order_thickness: null, // 厚み
      order_width: null, // 幅
      order_length: null, // 長さ
      order_number: 1, // 注文数
      order_house_name: '', // 商品名
      order_date: '', // 受注日
      delivery_date: '', // 納品日
      order_remark: '', // 備考
      cost_price: null, // 原価
      unit_price: null, // 単価
      processing_price_value: null, // 加工費
      sub_total: null, // 小計
      amount_money: null, // 注文金額
      specific_gravity: null, // 比重
      weight: null, // 重さ
      unit: '枚', // 単位
      total_weight: null, // 合計重量
    },
  ];
  const [editRows, setEditRows] = useState([]);
  const [addProductRows, setAddProductRows] = useState([]);
  const [addLoadingForm, setAddLoadingForm] = useState(false);
  const [isPageReload, setIsPageReload] = useState(false);
  const [clientInfo, setClientInfo] = useState({});
  const [selectedOrderDate, setSelectedOrderDate] = useState('');
  const [selectedOrderMoney, setSelectedOrderMoney] = useState(0);
  const [selectedDeliveryDate, setSelectedDeliveryDate] = useState('');
  const [subOrderData, setSubOrderData] = useState({});
  const [selectedSubOrderData, setSelectedSubOrderData] = useState({});
  const [subOrderGRows, setSubOrderGRows] = useState({});
  const [editModalRows, setEditModalRows] = useState([]);
  const [isCloseModal, setIsCloseModal] = useState(false);
  const [subOrderId, setSubOrderId] = useState('');
  const [selectedStockId, setSelectedStockId] = useState(null);
  const [selectedSubOrderRows, setSelectedSubOrderRows] = useState([]);
  const [openSelectStocksConfModal, setOpenSelectStocksConfModal] = useState(false);
  const [resetSelectedRowIds, setResetSelectedRowIds] = useState(false);
  const [deleteStockInput, setDeleteStockInput] = useState([]);
  const [deleteStockRows, setDeleteStockRows] = useState([]);
  const [isStockListModalFormValid, setIsStockListModalFormValid] = useState(true);
  const [isAddStockSelect, setIsAddStockSelect] = useState(false);
  const [isUpdateStockSelect, setIsUpdateStockSelect] = useState(false);
  const [openStockModal, setOpenStockModal] = useState(false);
  const [selectedSubOrderLength, setSelectedSubOrderLength] = useState(0);
  const [isAllStockBtn, setIsAllStockBtn] = useState(true);
  const [isClickSelectStocksBtn, setIsClickSelectStocksBtn] = useState(false);
  const [selectedStockIds, setSelectedStockIds] = useState([]);
  const [isStockSelectLoading, setIsStockSelectLoading] = useState(false);
  const [isClickOpenSelectStocks, setIsClickOpenSelectStocks] = useState(false);

  // カスタムフック
  const { isAddOrUpdateLoading, responseObj, responseObj2, addOrUpdateData } = useAddOrUpdateData();
  const { onlyNumbersAndPoint, useGetToday, convertNum, convertDot } = useCommonFunction();
  const { toggleSnack } = useSnackBar();
  const { deleteIds, setDeleteIds, deleteRows } = useDeleteForm('SubOrder', []);
  const {
    openProductModal,
    selectedProductId,
    setSelectedProductId,
    setOpenProductModal,
    handleClickSelectProduct,
    selectedProductRow,
    setSelectedProductRow,
  } = useSelectProductModal();
  const { handleClickCreateForm } = useCreateOrderForm();
  const { otherSettingList } = useGetOtherSetting();

  // 選択済みの在庫読み込み用URL
  const getUrl = `${process.env.REACT_APP_API_URL}/FILMa_GetStockSelectForUser?code=${process.env.REACT_APP_API_CODE}`;

  /**
   * 在庫選択を取得
   * @param {String} id
   */
  const getStockSelect = async (id) => {
    setIsStockSelectLoading(true);
    setIsLoading(true); // ローディン非表示
    const response = await fetch(getUrl, {
      method: 'POST',
      body: JSON.stringify([{ film_sub_orders_master_id: id }]),
    })
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        return false;
      });

    if (!response.code && response) {
      // 成功
      setFormInputValue(response.result);
    } else {
      // 失敗
      let status = 'error';
      let message = 'ページの読み込みに失敗しました';
      toggleSnack(true, status, message);
    }
    setIsLoading(false); // ローディング非表示
    setIsStockSelectLoading(false);
  };

  useEffect(() => {
    if (!subOrderId) return;
    getStockSelect(subOrderId);
  }, [subOrderId]);

  // 在庫選択画面 在庫を選択する
  const handleClickSelectStock = (params) => {
    // コピー
    let copyFormInputNewValue = JSON.parse(JSON.stringify(formInputNewValue));
    const object = {
      film_sub_orders_master_id: subOrderId,
      film_stocks_master_id: params.row.id,
      length_us: subOrderData.row.order_length,
      ProductName: params.row.ProductName,
      thickness: params.row.thickness,
      width: params.row.width,
      length_lo: params.row.length_lo - subOrderData.row.order_length,
    };

    // 一括在庫選択の場合
    if (selectedSubOrderRows.length > 0) {
      for (let i = 0; i < selectedSubOrderRows.length; i++) {
        copyFormInputNewValue.push({
          ...object,
          ...{
            film_sub_orders_master_id: selectedSubOrderRows[i].id,
            length_us: selectedSubOrderRows[i].order_length,
          },
        });
      }
      setOpenSelectStocksConfModal(true);
    } else {
      // 個別在庫選択の場合
      copyFormInputNewValue.push(object);
      for (let i = 0; i < copyFormInputNewValue.length; i++) {
        const num = i + 1;
        copyFormInputNewValue[i].id = 'new' + num; // 削除で使用するユニークなIDを追加
      }
      setOpenSelectStockModal(false);
      setIsAddStockSelect(true);
    }

    setSelectedStockId(params.row.id);
    setFormInputNewValue(copyFormInputNewValue);
  };

  const handleCloseStockModal = () => {
    setOpenSelectStockModal(false);
    setTotalLength(0);
    setIsCloseModal(true);
  };

  // 在庫選択を押したら
  const handleClickOpenSelectStock = (id, params) => {
    setSubOrderId(id);
    setOpenStockModal(true);
    setSubOrderData(params);
    setIsPageReload(false);
    setIsAllStockBtn(true);
    setSelectedSubOrderRows([]);
    setResetSelectedRowIds(true);
    setFormInputNewValue([]);
    //getStockSelect(id);
    setIsClickSelectStocksBtn(false);
    setIsClickOpenSelectStocks(false);
  };

  // 一括在庫選択を押したら
  const handleClickOpenSelectStocks = () => {
    setOpenSelectStockModal(true);
    setSubOrderData({ row: selectedSubOrderRows[0] });
    setIsAllStockBtn(false); //　一括在庫選択では全在庫ボタンは非表示
    setIsPageReload(false);
    setIsClickSelectStocksBtn(true);
    setIsClickOpenSelectStocks(true);

    // 選択中の注文の長さの合計を計算する
    let result = 0;
    for (let i = 0; i < selectedSubOrderRows.length; i++) {
      if (selectedSubOrderRows[i].order_length !== undefined) {
        result += selectedSubOrderRows[i].order_length;
      }
    }
    setSelectedSubOrderLength(result);
  };

  /**
   * 在庫リストモーダル閉じる
   */
  const handleCloseStockListModal = () => {
    // setOpenStockModal(false);
    // setFormInputValue([]);
    // setFormInputNewValue([]);
    setDeleteStockInput([]);
    setDeleteStockRows([]);
    setIsStockListModalFormValid(false);
    setIsAddStockSelect(false);
    setIsUpdateStockSelect(false);

    // モダールを閉じたら選択欄をリセットしたいのでリダイレクトする
    let url = new URL(window.location.href);

    // ダッシュボードの時
    if (url.pathname === '/') {
      window.location.replace(location.href);
      return;
    }

    // 加工一覧の時
    if (url.pathname === '/processing/list') {
      window.location.replace(location.href);
      return;
    }

    // 注文詳細の時
    if (
      url.search.split('&')[1] !== undefined &&
      url.search.split('&')[1].indexOf('film_sub_orders_master_id') === 0
    ) {
      window.location.replace(location.href);
    } else {
      window.location.replace(
        url.origin + url.pathname + url.search.split('&')[0] + '&OpenSubOrderId=' + subOrderId,
      );
    }
  };

  // 在庫選択画面 合計の長さを計算する
  useEffect(() => {
    let c = 0;
    for (let i = 0; i < formInputValue.length; i++) {
      if (formInputValue[i].length_us !== undefined) {
        c += Number(formInputValue[i].length_us);
      }
    }
    for (let i = 0; i < formInputNewValue.length; i++) {
      if (formInputNewValue[i].length_us !== undefined) {
        c += Number(formInputNewValue[i].length_us);
      }
    }

    setTotalLength(c);
  }, [formInputValue, formInputNewValue]);

  // 在庫リストの選択在庫チェック
  useEffect(() => {
    if (formInputNewValue.length === 0) {
      setIsAddStockSelect(false);
    }
    if (formInputValue.length === 0) {
      setIsUpdateStockSelect(false);
    }
  }, [formInputValue, formInputNewValue]);

  // 在庫リストの登録ボタンの状態管理
  useEffect(() => {
    let status = true;
    let copyInput = JSON.parse(JSON.stringify(formInputValue));
    let copyNewInput = JSON.parse(JSON.stringify(formInputNewValue));
    // マージする
    let inputMerge = copyInput.concat(copyNewInput);

    if (
      // totalLengthがorderLength以上、かつ新規登録があれば登録ボタン押下可
      (totalLength >= orderLength && isAddStockSelect) ||
      // totalLengthがorderLength以上、かつ更新があれば登録ボタン押下可
      (totalLength >= orderLength && isUpdateStockSelect) ||
      // totalLengthが1以上、かつtotalLengthがorderLength以上、かつ登録済み在庫の削除が一つでもあれば登録ボタン押下可
      (totalLength > 0 && totalLength >= orderLength && deleteStockInput.length > 0) ||
      // totalLengthが0、かつ登録済み在庫の削除が一つでもあれば登録ボタン押下可
      (totalLength === 0 && deleteStockInput.length > 0)
    ) {
      status = false;
    }

    // 在庫選択時に割り当てる在庫の残長論理以上の長さは登録できないようにする
    const idx = inputMerge.findIndex((d) => d.length_lo < 0); // 該当データのindexを取得
    if (idx >= 0) {
      status = true;
    }

    setIsStockListModalFormValid(status);
  }, [
    orderLength,
    totalLength,
    isAddStockSelect,
    isUpdateStockSelect,
    deleteStockInput,
    formInputValue,
    formInputNewValue,
  ]);

  /**
   * True Falseの文字列をBooleanに変換
   */
  const toBoolean = (data) => {
    return String(data).toLowerCase() === 'true';
  };

  /** 在庫選択画面 更新入力時のchangeハンドラー */
  const handleChangeUpdateForm = (e, type, index, isAdd) => {
    if (e.target.value === undefined) return;
    let value = e.target.value;
    let copyInput = [];

    if (isAdd) {
      // 登録
      copyInput = JSON.parse(JSON.stringify(formInputNewValue));
    } else {
      // 更新
      copyInput = JSON.parse(JSON.stringify(formInputValue));
    }

    // typeに応じて型を指定する
    switch (type) {
      case 'number':
        let convertValue = convertDot(e.target.value);
        convertValue = convertNum(convertValue);
        convertValue = onlyNumbersAndPoint(convertValue);
        value = Number(convertValue);
        break;
      case 'bool':
        value = toBoolean(e.target.value);
        break;
      default:
        break;
    }

    if (e.target.name === 'length_us') {
      copyInput[index]['length_lo'] =
        copyInput[index]['length_lo'] - (value - copyInput[index]['length_us']);
    }
    copyInput[index][e.target.name] = value;

    if (isAdd) {
      // 登録
      setFormInputNewValue(copyInput);
    } else {
      // 更新
      setFormInputValue(copyInput);
      setIsUpdateStockSelect(true);
    }
  };

  /**
   * 在庫選択画面 削除ボタン押下
   * @param {String} id 削除するID
   */
  const handleClickDeleteStock = (id) => {
    setIsStockSelectLoading(true);
    const copyFormInputNewValue = JSON.parse(JSON.stringify(formInputNewValue)); // コピー
    const inputNewIdx = copyFormInputNewValue.findIndex((d) => d.id === id); // 該当データのindexを取得

    // 新しく登録する在庫の場合の処理
    if (formInputNewValue.length > 0 && inputNewIdx >= 0) {
      // idx番目の要素を削除する
      copyFormInputNewValue.splice(inputNewIdx, 1);
      setFormInputNewValue(copyFormInputNewValue);
    }

    const copyFormInputValue = JSON.parse(JSON.stringify(formInputValue)); // コピー
    const inputIdx = copyFormInputValue.findIndex((d) => d.id === id); // 該当データのindexを取得

    const copyDeleteStockRows = JSON.parse(JSON.stringify(deleteStockRows)); // コピー

    // 登録済みの在庫の場合の処理
    if (formInputValue.length > 0 && inputIdx >= 0) {
      // 削除するレコードを追加する
      copyDeleteStockRows.push(copyFormInputValue[inputIdx]);

      // idx番目の要素を削除する
      copyFormInputValue.splice(inputIdx, 1);
      setFormInputValue(copyFormInputValue);

      // 在庫削除用のinput作成
      const copyDeleteStockInput = JSON.parse(JSON.stringify(deleteStockInput));
      copyDeleteStockInput.push({ id: id });
      setDeleteStockInput(copyDeleteStockInput);

      // 在庫履歴更新用のリストを作成
      setDeleteStockRows(copyDeleteStockRows);
    }
    setIsStockSelectLoading(false);
  };

  /**
   * 在庫選択画面の更新ボタンを押下
   * @param {Boolean} isBulkRegistration 一括登録だったらtrueがくる
   */
  const handleClickUpdateRows = async (isBulkRegistration) => {
    setAddLoadingForm(true);
    setAddLoadingUpdateForm(true); // ローディング表示
    setIsStockSelectLoading(true);

    let postBody = {
      Input: JSON.parse(JSON.stringify(formInputValue)),
      NewInput: JSON.parse(JSON.stringify(formInputNewValue)),
      isUpdate: isUpdateStockSelect,
      deleteStock: deleteStockInput,
      isBulk: isBulkRegistration ? isBulkRegistration : false,
      subOrderId: subOrderId,
    };

    const url = `${process.env.REACT_APP_API_URL}/FILMa_UpdateSelectStockBulk?code=${process.env.REACT_APP_API_CODE}`;

    let status = 'success';
    let message = '更新が完了しました';

    let response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(postBody),
    })
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        setAddLoadingUpdateForm(false);
        setAddLoadingForm(false);
        return false;
      });

    if (response === false || response.code) {
      // 失敗時
      status = 'error';
      message = '更新に失敗しました。登録内容を再度確認してください。';
      toggleSnack(true, status, message);
      setAddLoadingUpdateForm(false);
      setAddLoadingForm(false);
      setIsStockSelectLoading(false);
      return;
    }

    toggleSnack(true, status, message);
    setIsStockSelectLoading(false);
    handleCloseStockListModal();
  };

  // 在庫登録時のレスポンスが返ってきたあとの処理
  useEffect(() => {
    if (!Object.keys(responseObj).length) return;

    let status = 'success';
    let message = '登録が完了しました';

    if (responseObj.code) {
      // 失敗時
      status = 'error';
      message = '登録に失敗しました' + ' FILMa_AddStock';

      // エラーコードがあれば足す
      if (responseObj.code) {
        message = message + '[' + responseObj.code + ']';
      }
    } else {
      // 成功時
    }

    // 失敗時 error
    if (responseObj.error) {
      status = 'error';
      message = '登録に失敗しました' + ' FILMa_AddStock';
    }

    toggleSnack(true, status, message);
  }, [responseObj]);

  // 選択中の在庫IDをまとめる
  useEffect(() => {
    let copyInput = JSON.parse(JSON.stringify(formInputValue));
    let copyNewInput = JSON.parse(JSON.stringify(formInputNewValue));
    const ids = [];
    // マージする
    let inputMerge = copyInput.concat(copyNewInput);
    for (let i = 0; i < inputMerge.length; i++) {
      ids.push(inputMerge[i].film_stocks_master_id);
    }
    setSelectedStockIds(ids);
  }, [formInputValue, formInputNewValue]);

  /**
   * 更新処理
   * @param {String} table
   * @param {Object} input
   * @param {String} redirectUrl
   */
  const updateDatas = async (table, input, redirectUrl) => {
    setAddLoadingForm(true); // ローディング表示

    const url = `${process.env.REACT_APP_API_URL}/FILMa_Update${table}?code=${process.env.REACT_APP_API_CODE}`;

    let response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(input),
    })
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        return false;
      });

    setAddLoadingForm(false); // ローディング非表示

    let status = 'success';
    let message = '更新が完了しました';
    if (response === false || response.code) {
      // 失敗時
      status = 'error';
      message = '更新に失敗しました' + ' FILMa_Update' + table;
    } else {
      // 成功時
      if (redirectUrl) {
        window.location.replace(location.origin + redirectUrl); // 一覧へリダイレクト
      } else {
        setOpenSelectStocksConfModal(false);
        setOpenSelectStockModal(false);
        setIsPageReload(true);
        setSelectedSubOrderRows([]);
      }
    }

    // エラーコードがあれば足す
    if (response.code) {
      message = message + '[' + response.code + ']';
    }

    toggleSnack(true, status, message);
  };

  // 注文の長さを取得
  useEffect(() => {
    if (subOrderData === undefined) return;
    if (subOrderData.row === undefined) return;
    if (subOrderData.row.order_length === undefined) return;
    if (!Object.keys(subOrderData).length) return;
    setOrderLength(subOrderData.row.order_length);
  }, [subOrderData]);

  /**
   * 加工票を表示する
   * @param {Object} params
   */
  const handleClickProcessingSheet = (params) => {
    if (Array.isArray(params.row)) {
      setPdfData(params.row);
    } else {
      setPdfData([params.row]);
    }
    setOpenProcessingSheet(true);
  };

  /**
   * 加工指示の印刷を押したらステータスを更新する
   * ※元のステータスコードが0の場合にステータスを更新する
   */
  const printProcessingSheet = (datas) => {
    const input = [];
    for (let i = 0; i < datas.length; i++) {
      if (datas[i].StatusCode === undefined || datas[i].StatusCode !== 0) {
        continue;
      }
      input.push(changeSubOrderStatus(datas[i], 1));
    }

    if (input.length > 0) {
      // ステータス更新
      updateDatas('SubOrder', input);
    }
  };

  /**
   * 注文のスータス変更用の関数
   * @param {Object} obj レコードのオブジェクト
   * @param {Number} code ステータスコードのindex
   * @returns
   */
  const changeSubOrderStatus = (obj, code) => {
    const copyObj = Object.assign({}, JSON.parse(JSON.stringify(obj))); // コピー
    copyObj.status = SUB_ORDER_STATUS_LIST[code]; // 加工指示書出力済に書き換える

    // 更新のinput用にオブジェクトを作成
    const copyInput = { ...SUB_ORDER_UPDATE_INPUT };
    for (const key in copyInput) {
      if (key in copyObj) {
        copyInput[key] = copyObj[key];
      }
    }

    return copyInput;
  };

  // 受注詳細画面からの更新ボタン押下後
  const handleClickUpdateOrderForm = async (isModal) => {
    setAddLoadingForm(true);
    let update_order_and_sub_order_body = {
      'delete_ids': [], // 削除する注文
      'add_sub_order': [], // 追加する注文
      'update_order1': [], // 追加する注文に合わせて更新する受注
      'update_order2': [], // 更新する受注
      'update_sub_order_to_stock_select': [],
    }
    let deleteResponse = {};
    // 削除する注文があれば削除処理を行う
    if (deleteIds.length > 0) {
      let ids = [];
      // 受注詳細は注文が束ねられているので、その他の注文もまとめて削除する
      for (let i = 0; i < deleteIds.length; i++) {
        for (const key in subOrderGRows) {
          const idx = subOrderGRows[key].findIndex((d) => d.id === deleteIds[i]); // 該当データのindexを取得
          if (idx >= 0) {
            for (let c = 0; c < subOrderGRows[key].length; c++) {
              ids.push(subOrderGRows[key][c].id);
            }
          }
        }
      }
      update_order_and_sub_order_body['delete_ids'] = ids
    }

    let copyEditRows = [];
    let inputAddRows = [];
    let inputEditRows = [];

    if (isModal) {
      copyEditRows = JSON.parse(JSON.stringify(editModalRows)); // コピー
    } else {
      copyEditRows = JSON.parse(JSON.stringify(editRows)); // コピー

      for (let i = 0; i < copyEditRows.length; i++) {
        if (String(copyEditRows[i].id).split('_')[0] === 'add') {
          // idの先頭にaddなら登録のinputに追加
          inputAddRows.push(copyEditRows[i]);
        } else {
          // 編集用のinputに追加
          inputEditRows.push(copyEditRows[i]);
        }
      }
      copyEditRows = inputEditRows;
    }

    // 受注用input
    let input = [
      {
        id: '',
        film_clients_master_id: clientInfo.id,
        order_date: selectedOrderDate,
        delivery_date: selectedDeliveryDate,
        amount_money: selectedOrderMoney,
      },
    ];

    // 登録するデータがあれば登録処理を行う
    if (inputAddRows.length > 0) {
      // 注文登録用のinput作成
      let addSubOrder = [];
      for (let i = 0; i < inputAddRows.length; i++) {
        let uuid = uuidv4();
        for (let j = 0; j < inputAddRows[i].order_number; j++) {
          const object = {
            film_orders_master_id: inputAddRows[i].film_orders_master_id, // 受注ID
            sub_order_group_id: String(inputAddRows[i].film_orders_master_id + '_' + uuid + '_' + (j + 1)), // 注文グループID
            order_thickness: inputAddRows[i].order_thickness !== null ? inputAddRows[i].order_thickness : null, // 注文/厚み
            order_width: inputAddRows[i].order_width !== null ? inputAddRows[i].order_width : null, // 注文/幅
            order_length: inputAddRows[i].order_length !== null ? inputAddRows[i].order_length : null, // 注文/厚み
            order_process: inputAddRows[i].order_process !== null ? inputAddRows[i].order_process : '', // 注文/加工方法
            order_number: inputAddRows[i].order_number !== null ? inputAddRows[i].order_number : null, // 注文数
            order_date: inputAddRows[i].order_date !== null ? inputAddRows[i].order_date : null, // 受注日
            delivery_date: inputAddRows[i].delivery_date !== null ? inputAddRows[i].delivery_date : null, // 納品日
            unit_price: inputAddRows[i].unit_price !== null ? inputAddRows[i].unit_price : 0, // 単価
            cost_price: inputAddRows[i].cost_price !== null ? inputAddRows[i].cost_price : 0, // 原価
            processing_price_value:
              inputAddRows[i].processing_price_value !== undefined
                ? inputAddRows[i].processing_price_value
                : 0, // 加工費
            sub_total: inputAddRows[i].sub_total !== null ? inputAddRows[i].sub_total : 0, // 小計
            amount_money: inputAddRows[i].amount_money !== null ? inputAddRows[i].amount_money : 0, // 注文金額
            order_house_name: inputAddRows[i].order_house_name !== null ? inputAddRows[i].order_house_name : '', // ハウス名
            order_remark: inputAddRows[i].order_remark !== null ? inputAddRows[i].order_remark : '', // 注文/備考
            sub_order_remark: '', // 注文/備考
            status: '新規', // ステータス
            processing_client_name: clientInfo.name !== null ? clientInfo.name : '', // 加工/顧客名
            processing_title_of_honor: clientInfo.titleOfHonor !== null ? clientInfo.titleOfHonor : '',
            film_products_master_id: inputAddRows[i].film_products_master_id !== null ? inputAddRows[i].film_products_master_id : '', // 商品ID
            processing_product_name:
              inputAddRows[i].processing_product_name !== null ? inputAddRows[i].processing_product_name : '', // 商品名
            process_date: null, // 加工日
            ship_date: null, // 出荷日
            consumption_tax:
              otherSettingList[0].consumption_tax !== undefined
                ? otherSettingList[0].consumption_tax
                : '', // 消費税率
            unit: inputAddRows[i].unit !== undefined ? inputAddRows[i].unit : '', // 単位
            weight: inputAddRows[i].weight !== undefined ? inputAddRows[i].weight : '', // 重さ
            total_weight:
              inputAddRows[i].total_weight !== undefined ? inputAddRows[i].total_weight : '', // 合計重量
            outsourcing:
              inputAddRows[i].outsourcing !== undefined ? inputAddRows[i].outsourcing : '', // 外注
          };
          addSubOrder.push(object);
        }
      }

      input[0].id = inputAddRows[0].film_orders_master_id;

      update_order_and_sub_order_body['add_sub_order'] = addSubOrder
      update_order_and_sub_order_body['update_order1'] = input
    }

    // 更新するデータがあれば更新処理を行う
    if (copyEditRows.length > 0) {
      const redirectUrl = '/order/list';

      input[0].id = copyEditRows[0].film_orders_master_id;

      const input2 = [];
      for (let i = 0; i < copyEditRows.length; i++) {
        // idが無いデータは追加しない
        if (copyEditRows[i].id === '') {
          continue;
        }

        // 注文金額は注文数で割る
        let amountMoney =
          copyEditRows[i].amount_money !== undefined ? copyEditRows[i].amount_money : 0;
        const num = copyEditRows[i].order_number !== undefined ? copyEditRows[i].order_number : 1;
        amountMoney = amountMoney;

        const object = {
          id: copyEditRows[i].id,
          film_orders_master_id: copyEditRows[i].film_orders_master_id, // 受注ID
          sub_order_group_id: copyEditRows[i].sub_order_group_id !== null ? copyEditRows[i].sub_order_group_id : '', // 注文グループID
          order_thickness: copyEditRows[i].order_thickness !== null ? copyEditRows[i].order_thickness : null, // 注文/厚み
          order_width: copyEditRows[i].order_width !== null ? copyEditRows[i].order_width : null, // 注文/幅
          order_length: copyEditRows[i].order_length !== null ? copyEditRows[i].order_length : null, // 注文/厚み
          order_process: copyEditRows[i].order_process !== null ? copyEditRows[i].order_process : '', // 注文/加工方法
          order_number: copyEditRows[i].order_number !== null ? copyEditRows[i].order_number : null, // 注文数
          order_date: copyEditRows[i].order_date !== null ? copyEditRows[i].order_date : null, // 受注日
          delivery_date: copyEditRows[i].delivery_date !== null ? copyEditRows[i].delivery_date : null, // 納品日
          unit_price: copyEditRows[i].unit_price !== null ? copyEditRows[i].unit_price : 0, // 単価
          cost_price: copyEditRows[i].cost_price !== null ? copyEditRows[i].cost_price : 0, // 原価
          processing_price_value:
            copyEditRows[i].processing_price_value !== undefined
              ? copyEditRows[i].processing_price_value
              : 0, // 加工費
          sub_total: copyEditRows[i].sub_total !== null ? copyEditRows[i].sub_total : 0, // 小計
          amount_money: amountMoney, // 注文金額
          order_house_name: copyEditRows[i].order_house_name !== null ? copyEditRows[i].order_house_name : '', // ハウス名
          order_remark: copyEditRows[i].order_remark !== null ? copyEditRows[i].order_remark : '', // 注文/備考
          sub_order_remark: copyEditRows[i].sub_order_remark !== null ? copyEditRows[i].sub_order_remark : '', // 注文/備考
          status: copyEditRows[i].status !== null ? copyEditRows[i].status : '', // ステータス
          processing_client_name: copyEditRows[i].processing_client_name !== null ? copyEditRows[i].processing_client_name : '', // 加工/顧客名
          processing_title_of_honor:
            copyEditRows[i].processing_title_of_honor !== null ? copyEditRows[i].processing_title_of_honor : '',
          film_products_master_id: copyEditRows[i].film_products_master_id !== null ? copyEditRows[i].film_products_master_id : '', // 商品ID
          processing_product_name:
            copyEditRows[i].processing_product_name !== null ? copyEditRows[i].processing_product_name : '', // 商品名
          process_date: null, // 加工日
          ship_date: null, // 出荷日
          consumption_tax:
            copyEditRows[i].consumption_tax !== undefined ? copyEditRows[i].consumption_tax : '', // 消費税率
          unit: copyEditRows[i].unit !== undefined ? copyEditRows[i].unit : '', // 単位
          weight: copyEditRows[i].weight !== undefined ? copyEditRows[i].weight : '', // 重さ
          total_weight: copyEditRows[i].total_weight !== undefined ? copyEditRows[i].total_weight : '', // 合計重量
          outsourcing: copyEditRows[i].outsourcing !== undefined ? copyEditRows[i].outsourcing : '', // 外注
        };

        let subOrderGIdAry = copyEditRows[i].sub_order_group_id.split('_');

        // 受注詳細の場合は注文数分更新する
        if (subOrderGRows[subOrderGIdAry[1]] && !isModal) {
          for (const property in subOrderGRows[subOrderGIdAry[1]]) {
            input2.push({
              ...object,
              ...{
                id: subOrderGRows[subOrderGIdAry[1]][property].id,
                sub_order_group_id: subOrderGRows[subOrderGIdAry[1]][property].sub_order_group_id,
                sub_order_remark: subOrderGRows[subOrderGIdAry[1]][property].sub_order_remark,
              },
            });
          }
        } else {
          input2.push(object);
        }
      }

      // モーダルからの更新の際は受注は更新しない
      if (!isModal) {
        update_order_and_sub_order_body['update_order2'] = input
      }
      update_order_and_sub_order_body['update_sub_order_to_stock_select'] = input2
      await updateDatas('OrderAndSubOrder', update_order_and_sub_order_body, redirectUrl);
    } else {
      await updateDatas('OrderAndSubOrder', update_order_and_sub_order_body);
    }

    let url = new URL(window.location.href);
    // 削除にorderまで消したかどうか
    if (deleteIds.length > 0 && deleteResponse.result) {
      window.location.replace(url.origin + '/order/list');
      return;
    }
    // 注文詳細の時
    if (!isModal) {
      window.location.replace(url.origin + url.pathname + url.search.split('&')[0]);
      return;
    }
    if (
      url.search.split('&')[1] !== undefined &&
      url.search.split('&')[1].indexOf('film_sub_orders_master_id') === 0
    ) {
      window.location.replace(location.href);
    } else {
      window.location.replace(
        url.origin +
        url.pathname +
        url.search.split('&')[0] +
        '&OpenSubOrderId=' +
        copyEditRows[0]['id'],
      );
    }
    setAddLoadingForm(false);
  };

  const reloadPage = () => {
    setIsPageReload(true);
  };

  /**
   * 加工開始ボタンを押下後
   * @param {Object} data
   */
  const handleClickStartProcessingBtn = async (data, status, newStatus) => {
    if (data === undefined) return;
    if (!Object.keys(data).length) return;
    setAddLoadingUpdateForm(true);

    // 注文のステータスを更新する
    if (data.StatusCode !== undefined && data.StatusCode <= status) {
      if (newStatus === 4) {
        let input = {
          subOrderList: [changeSubOrderStatus(data, newStatus)],
          stockSelectList: formInputValue,
        };
        await updateDatas('SubOrderForProcessing', input);
      } else {
        await updateDatas('SubOrder', [changeSubOrderStatus(data, newStatus)]); // 注文のステータスを加工作業中にする
      }
    }
    // 画面更新のために同じ画面へリダイレクト
    setAddLoadingUpdateForm(false);
    window.location.replace(location.origin + '/order/detail' + location.search);
  };

  /**
   * 出荷伝票印刷ボタンを押下後ステータスを更新する
   * @param {Array} datas [[{...}, {...}], [{...}]]
   */
  const handleClickPrintShipSheet = (datas, status, newStatus) => {
    if (datas === undefined) return;
    if (!datas.length) return;

    const input = [];
    for (let c = 0; c < datas.length; c++) {
      let copyRows = JSON.parse(JSON.stringify(subOrderGRows[datas[c].sub_order_group_id.split('_')[1]]));
      for (let i = 0; i < copyRows.length; i++) {
        if (copyRows[i].StatusCode !== undefined && copyRows[i].StatusCode !== status) {
          continue;
        }
        input.push(changeSubOrderStatus(copyRows[i], newStatus)); // 注文のステータスを出荷済にする
      }
    }

    if (input.length > 0) {
      // 注文更新
      updateDatas('SubOrder', input);
    }
  };

  /**
   * 注文情報を取得
   * @param {String} id
   */
  const getSubOrderFromQRCode = async (id) => {
    const getSubOrderUrl = `${process.env.REACT_APP_API_URL}/FILMa_GetSubOrderForUser?code=${process.env.REACT_APP_API_CODE}`;
    const response = await fetch(getSubOrderUrl, {
      method: 'POST',
      body: JSON.stringify([{ id: id }]),
    })
      .then((response) => {
        setIsLoading(false); // ローディング非表示
        return response.json();
      })
      .catch((error) => {
        setIsLoading(false); // ローディング非表示
        return false;
      });

    if (!response.code && response) {
      // 成功
      if (response.result.length >= 0) {
        setSubOrderData(response.result[0]);
      } else {
        let status = 'status';
        let message = '注文が存在しません';
        toggleSnack(true, status, message);
      }
    } else {
      // 失敗
      let status = 'error';
      let message = '注文の取得に失敗しました';
      toggleSnack(true, status, message);
    }
  };

  /**
   * 加工/在庫ボタンを押下後
   * @param {} e
   */
  const handleClickProcessingAndStockBtn = (e) => {
    setOpenSubOrderListModal(true);
    setSelectedSubOrderData(e);
  };

  const handleClickProcessingBulk = async (rows) => {
    let url = new URL(window.location.href);

    await updateDatas('SubOrderForProcessingBulk', rows);

    // 受注詳細の加工済変更ボタンから変更した時はそのままリロード
    if (url.search.split('&')[1] === undefined) {
      window.location.replace(location.href);
      return;
    }

    // 注文詳細の時
    if (
      url.search.split('&')[1] !== undefined &&
      url.search.split('&')[1].indexOf('film_sub_orders_master_id') === 0
    ) {
      window.location.replace(location.href);
    } else {
      window.location.replace(
        url.origin + url.pathname + url.search.split('&')[0] + '&OpenSubOrderId=' + rows[0]['id'],
      );
    }
  };

  /** セル編集開始 */
  const cellEditStart = useCallback(
    (params) => {
      if (String(params.row.id).split('_')[0] === 'add') return; // 新しく追加するレコードは除外
      if (subOrderGRows[params.row.sub_order_group_id.split('_')[1]] === undefined) return;

      // ・長さ、加工方法
      // ステータスが在庫割当済以前の注文は全て編集内容を反映
      // ステータスが加工作業中以降の注文も反映するが、入力中に下記注意書きのポップアップを表示する
      if (params.field === 'order_length' || params.field === 'order_process') {
        let idx = subOrderGRows[params.row.sub_order_group_id.split('_')[1]].findIndex(
          (d) => d.StatusCode > 2,
        );
        if (idx >= 0) {
          toggleSnack(
            true,
            'warning',
            '変更する注文にステータスが新規、加工指示書出力済、在庫割当済以外の注文が存在します。変更する場合は注文詳細から変更ください。',
          );
        }
      }

      // ・納品日、原価、単価、単位、加工費、小計、注文金額、ハウス名、備考
      // ステータスが出荷済以外の注文は全て編集可能で編集内容を反映
      // 出荷済の注文がある場合も編集内容を反映するが、入力中に下記注意書きのポップアップを表示する
      if (
        params.field === 'delivery_date' ||
        params.field === 'cost_price' ||
        params.field === 'unit_price' ||
        params.field === 'unit' ||
        params.field === 'processing_price_value' ||
        params.field === 'sub_total' ||
        params.field === 'amount_money' ||
        params.field === 'order_house_name' ||
        params.field === 'order_remark'
      ) {
        let idx = subOrderGRows[params.row.sub_order_group_id.split('_')[1]].findIndex(
          (d) => d.StatusCode === 5,
        );
        if (idx >= 0) {
          toggleSnack(
            true,
            'warning',
            '変更する注文にステータスが出荷済の注文が存在します。変更する場合は注文詳細から変更ください。',
          );
        }
      }
    },
    [subOrderGRows],
  );

  return {
    formInputValue,
    formInputNewValue,
    setFormInputNewValue,
    isStockListModalFormValid, // 在庫リストモーダルの更新ボタンを押せるか
    errors,
    addLoadingUpdateForm, // ローディング
    openSelectStockModal,
    setOpenSelectStockModal,
    handleChangeUpdateForm, // 更新フォームのチェンジメソッド
    handleClickUpdateRows, // 更新を行うメソッド
    handleClickSelectStock,
    totalLength, // 合計の長さ
    orderLength, // 注文の長さ
    handleCloseStockModal,
    handleClickProcessingSheet, // 加工指示を表示したときの関数
    printProcessingSheet, // 加工指示の印刷を押したときの関数
    pdfData, // 加工票pdfに表示するデータ
    openProcessingSheet, // 加工票を表示する
    setOpenProcessingSheet, // 加工票を表示する
    handleClickUpdateOrderForm, // 受注詳細画面からの更新ボタンを押したときの関数
    deleteIds, // 削除するIDリスト
    setDeleteIds, // 削除するIDリスト作成
    emptyRows, // 空のレコード用のデータ
    editRows, // 編集したデータ
    setEditRows, // 編集したデータ
    isPageReload,
    setIsPageReload,
    reloadPage,
    clientInfo,
    setClientInfo,
    selectedOrderDate,
    setSelectedOrderDate,
    selectedOrderMoney,
    setSelectedOrderMoney,
    selectedDeliveryDate,
    setSelectedDeliveryDate,
    handleClickStartProcessingBtn,
    subOrderData,
    setSubOrderData,
    getSubOrderFromQRCode,
    openSubOrderListModal,
    setOpenSubOrderListModal,
    handleClickProcessingAndStockBtn,
    selectedSubOrderData,
    addLoadingForm,
    setSubOrderGRows,
    editModalRows,
    setEditModalRows,
    isCloseModal,
    setIsCloseModal,
    subOrderId,
    setSubOrderId,
    isLoading,
    selectedSubOrderRows,
    setSelectedSubOrderRows,
    openSelectStocksConfModal,
    setOpenSelectStocksConfModal,
    selectedStockId,
    resetSelectedRowIds,
    setResetSelectedRowIds,
    setSelectedSubOrderData,
    getStockSelect,
    handleClickDeleteStock,
    handleCloseStockListModal,
    openStockModal,
    setOpenStockModal,
    selectedSubOrderLength,
    handleClickOpenSelectStocks,
    handleClickOpenSelectStock,
    isAllStockBtn,
    isClickSelectStocksBtn,
    selectedStockIds,
    openProductModal,
    selectedProductId,
    setSelectedProductId,
    setOpenProductModal,
    handleClickSelectProduct,
    addProductRows,
    setAddProductRows,
    selectedProductRow,
    setSelectedProductRow,
    subOrderGRows,
    selectedSubOrderDeliveryDate,
    setSelectedSubOrderDeliveryDate,
    isFormValidation,
    setIsFormValidation,
    cellEditStart,
    isStockSelectLoading,
    handleClickPrintShipSheet,
    handleClickProcessingBulk,
    isClickOpenSelectStocks,
    changeSubOrderStatus,
    updateDatas,
  };
};
