import React, { useState } from 'react';

const initialParams = [
  {
    status: '原反(新品)',
  },
  {
    status: '原反(残反)',
  },
  {
    status: '仕掛品',
  },
  {
    status: 'その他',
  },
]; // デフォルト

/**
 * 一覧で廃棄済を含むを押したときに使う
 */
export const useDiscarded = () => {
  const [params, setParams] = useState(initialParams);

  const handleClickDiscarded = () => {
    let array = [];
    if (params.length === 0) {
      array = initialParams;
    }
    setParams(array);
  };

  return {
    params,
    handleClickDiscarded,
  };
};
