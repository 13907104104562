/** DataGrid日本語化(https://github.com/mui-org/material-ui-x/blob/HEAD/packages/grid/_modules_/grid/constants/localeTextConstants.ts) */
export const LOCALTEXT = {
  // Root
  noRowsLabel: 'データがありません',
  // Export selector toolbar button text
  toolbarExport: 'エクスポート',
  toolbarExportCSV: 'エクスポート',
  // Columns selector toolbar button text
  toolbarColumns: 'カラム',
  // Columns panel text
  columnsPanelTextFieldLabel: 'カラム検索',
  columnsPanelTextFieldPlaceholder: 'タイトル',
  columnsPanelShowAllButton: '全て表示',
  columnsPanelHideAllButton: '全て非表示',
  // Filters toolbar button text
  toolbarFilters: 'フィルタ',
  toolbarDensity: '行間設定',
  // Filter panel text
  filterPanelColumns: '要素',
  filterPanelOperators: '条件',
  filterPanelInputLabel: '値',
  filterOperatorContains: '含む',
  filterOperatorEquals: '等しい',
  filterOperatorStartsWith: '始まる',
  filterOperatorEndsWith: '終わる',
  filterOperatorIsEmpty: '空',
  filterOperatorIsNotEmpty: '空でない',
  filterOperatorIs: 'は',
  filterOperatorNot: 'ではない',
  filterOperatorAfter: '後',
  filterOperatorOnOrAfter: '以降',
  filterOperatorBefore: '前',
  filterOperatorOnOrBefore: '以前',
  filterPanelInputPlaceholder: 'フィルタ値',
  filterOperatorFrom: 'から',
  filterOperatorTo: 'まで',
  filterOperatorBetween: '範囲',
  // Density selector toolbar button text
  toolbarDensityCompact: '小',
  toolbarDensityStandard: '中',
  toolbarDensityComfortable: '大',
  // Checkbox selection text
  checkboxSelectionHeaderName: 'チェック',
};
