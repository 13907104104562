/*
  各ページのテーブルに表示するカラムを管理している。
  あとフィルタの定義も行っているようだ。
*/
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { format, parseISO, parse } from 'date-fns';
import { FilterDatePicker } from '../components/uiParts/FilterDatePicker';
import { useState, useEffect, useRef } from 'react';
import { useGridRootProps } from '@mui/x-data-grid';
import Box from '@mui/material/Box';

// valueParserにセットで半角数字(Number型)に変換してくれる
function parserNumber(params) {
  const value = params.replace(/[０-９]/g, function (s) {
    return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
  });
  return Number(value);
}

// valueParserにセットで半角数字(str型)に変換してくれる
function parserNumberStr(params) {
  const value = params.replace(/[０-９]/g, function (s) {
    return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
  });
  return value;
}

// 受注IDのリンク用
function orderLink(orderId, value) {
  return (
    <Link
      to={{
        pathname: '/order/detail?id=' + orderId,
      }}
    >
      {value}
    </Link>
  );
}

// 注文IDのリンク用
function subOrderLink(orderId, subOrderId, value) {
  return (
    <Link
      to={{
        pathname: '/order/detail?id=' + orderId + '&OpenSubOrderId=' + subOrderId,
      }}
    >
      {value}
    </Link>
  );
}

// 商品IDのリンク用
function ProductLink(productId, value) {
  return (
    <Link
      to={{
        pathname: '/product/detail?id=' + productId,
      }}
    >
      {value}
    </Link>
  );
}

// 顧客IDのリンク用
function ClientLink(clientId, value) {
  return (
    <Link
      to={{
        pathname: '/client/detail?id=' + clientId,
      }}
    >
      {value}
    </Link>
  );
}

// 日付入力用
function InputDateComponent(props) {
  const { item, applyValue } = props;

  const handleDateChange = (date) => {
    applyValue({ ...item, value: date });
  };

  return (
    <Box
      sx={{
        display: 'inline-flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: 48,
        pl: '20px',
      }}
    >
      <FilterDatePicker date={item.value} setDate={handleDateChange} />
    </Box>
  );
}

InputDateComponent.propTypes = {
  item: PropTypes.any,
  applyValue: PropTypes.any,
};

function InputBetweenDateComponent(props) {
  const rootProps = useGridRootProps();
  const { item, applyValue } = props;

  const filterTimeout = useRef();
  const [filterValueState, setFilterValueState] = useState(item.value ?? '');
  const [applying, setIsApplying] = useState(false);

  useEffect(() => {
    return () => {
      clearTimeout(filterTimeout.current);
    };
  }, []);

  useEffect(() => {
    const itemValue = item.value ?? [undefined, undefined];
    setFilterValueState(itemValue);
  }, [item.value]);

  const updateFilterValue = (startDate, endDate) => {
    clearTimeout(filterTimeout.current);
    setFilterValueState([startDate, endDate]);

    setIsApplying(true);
    filterTimeout.current = setTimeout(() => {
      setIsApplying(false);
      applyValue({ ...item, value: [startDate, endDate] });
    }, rootProps.filterDebounceMs);
  };

  const handleDateStartChange = (date) => {
    updateFilterValue(date, filterValueState[1]);
  };

  const handleDateEndChange = (date) => {
    updateFilterValue(filterValueState[0], date);
  };

  return (
    <Box
      width={330}
      sx={{
        display: 'inline-flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: 48,
        pl: '20px',
      }}
    >
      <FilterDatePicker date={filterValueState[0]} setDate={handleDateStartChange} />
      <FilterDatePicker date={filterValueState[1]} setDate={handleDateEndChange} />
    </Box>
  );
}

InputBetweenDateComponent.propTypes = {
  item: PropTypes.any,
  applyValue: PropTypes.any,
};

const filterOperators = [
  {
    value: 'from',
    getApplyFilterFn: (filterItem) => {
      if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
        return null;
      }
      return (params) => {
        const filterValue = parseISO(filterItem.value);
        const cellValue = params.value ? parseISO(params.value) : null;
        return cellValue >= filterValue;
      };
    },
    InputComponent: InputDateComponent,
    InputComponentProps: {
      type: 'string',
    },
  },
  {
    value: 'to',
    getApplyFilterFn: (filterItem) => {
      if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
        return;
      }
      return (params) => {
        const filterValue = parseISO(filterItem.value);
        const cellValue = params.value ? parseISO(params.value) : null;
        return cellValue <= filterValue;
      };
    },
    InputComponent: InputDateComponent,
    InputComponentProps: {
      type: 'string',
    },
  },
  {
    value: 'between',
    getApplyFilterFn: (filterItem) => {
      if (!Array.isArray(filterItem.value) || filterItem.value.length !== 2) {
        return null;
      }
      if (filterItem.value[0] == null || filterItem.value[1] == null) {
        return null;
      }
      return (params) => {
        const filterValueStart = parseISO(filterItem.value[0]);
        const filterValueEnd = parseISO(filterItem.value[1]);
        const cellValue = params.value ? parseISO(params.value) : null;
        return filterValueStart <= cellValue && cellValue <= filterValueEnd;
      };
    },
    InputComponent: InputBetweenDateComponent,
    InputComponentProps: {
      type: 'string',
    },
  },
];

/**
 * 特殊パターンの説明
 * renderCellがある場合は、見切れている時のホバーや3桁表示が反映されない。
 * typeが'number'かつisToLocaleStringがtrueの時に3桁表示される。
 */

// 商品カラム
export const PRODUCT_TABLE_COLUMNS = [
  {
    field: 'id', // テーブルで使用するカラム名
    headerName: '商品ID', // テーブルの列のタイトル
    type: 'number', // テーブルの型
    // formType: 'number', // フォームで使うtype
    width: 90, // テーブルのセルの横幅
    hide: false, // テーブルに表示させるか
    editable: false, // テーブルのセル上で編集させるか
    create: false, // 登録フォームで項目を使用するか
    update: true, // 更新フォームで項目を使用するか
    reauired: true, // 登録フォームで必須項目とするか
    disabled: true, // フォームで入力させない
    renderCell: (params) => ProductLink(params.row.id, params.value),
  },
  {
    field: 'name',
    headerName: '商品名',
    width: 150,
    editable: false,
    create: true,
    update: true,
    reauired: true,
    disabled: false,
  },
  {
    field: 'thickness',
    headerName: '厚み(T)',
    type: 'number',
    formType: 'number',
    width: 100,
    editable: false,
    create: true,
    update: true,
    reauired: true,
    disabled: false,
  },
  {
    field: 'width',
    headerName: '幅(W)',
    type: 'number',
    formType: 'number',
    width: 100,
    editable: false,
    create: true,
    update: true,
    reauired: true,
    disabled: false,
  },
  {
    field: 'length',
    headerName: '長さ(L)',
    type: 'number',
    formType: 'number',
    width: 100,
    editable: false,
    create: true,
    update: true,
    reauired: true,
    disabled: false,
  },
  {
    field: 'specific_gravity',
    headerName: '比重',
    type: 'number',
    formType: 'number',
    width: 100,
    editable: false,
    create: true,
    update: true,
    reauired: true,
    disabled: false,
  },
  {
    field: 'weight',
    headerName: '重量',
    type: 'number',
    formType: 'number',
    width: 100,
    editable: false,
    create: false,
    update: true,
    reauired: false,
    disabled: true,
  },
  {
    field: 'cost_price_m',
    headerName: '原価/m',
    type: 'number',
    formType: 'number',
    width: 100,
    editable: false,
    create: true,
    update: true,
    reauired: true,
    disabled: false,
  },
  {
    field: 'cost_price',
    headerName: '原価',
    type: 'number',
    formType: 'number',
    width: 100,
    editable: false,
    create: true,
    update: true,
    reauired: true,
    disabled: false,
  },
  {
    field: 'film_form_types_master_id',
    headerName: '形状',
    width: 100,
    type: 'number',
    editable: false,
    create: true,
    update: true,
    reauired: false,
    reauired: true,
    disabled: false,
    formType: 'select',
  },
  {
    field: 'FormName',
    headerName: '形状',
    width: 100,
    editable: false,
    create: false,
    update: false,
  },
  {
    field: 'detail',
    headerName: '商品コード',
    type: 'string',
    width: 200,
    editable: false,
    create: false,
    update: true,
    reauired: false,
    disabled: true,
  },
  {
    field: 'SellingPriceM1',
    headerName: '販売価格標準',
    type: 'string',
    width: 130,
    editable: false,
    create: false,
    update: false,
  },
  {
    field: 'SellingPriceM2',
    headerName: '販売価格A',
    type: 'string',
    width: 130,
    editable: false,
    create: false,
    update: false,
  },
  {
    field: 'SellingPriceM3',
    headerName: '販売価格B',
    type: 'string',
    width: 130,
    editable: false,
    create: false,
    update: false,
  },
  {
    field: 'SellingPriceM4',
    headerName: '販売価格C',
    type: 'string',
    width: 130,
    editable: false,
    create: false,
    update: false,
  },
  {
    field: 'ProcessingPrice1',
    headerName: '加工費標準',
    type: 'string',
    width: 130,
    editable: false,
    create: false,
    update: false,
  },

  {
    field: 'ProcessingPrice2',
    headerName: '加工費A',
    type: 'string',
    width: 130,
    editable: false,
    create: false,
    update: false,
  },
  {
    field: 'ProcessingPrice3',
    headerName: '加工費B',
    type: 'string',
    width: 130,
    editable: false,
    create: false,
    update: false,
  },
  {
    field: 'ProcessingPrice4',
    headerName: '加工費C',
    type: 'string',
    width: 130,
    editable: false,
    create: false,
    update: false,
  },
  {
    field: 'usage_category',
    headerName: '使用区分',
    type: 'string',
    width: 130,
    editable: false,
    create: true,
    update: true,
    reauired: true,
    formType: 'select',
    valueOptions: ['許可', '禁止'],
    defaultValueOption: '許可',
  },
];

export const PRODUCT_TABLE_COLUMNS_FOR_DASHBORD = [
  {
    field: 'id', // テーブルで使用するカラム名
    headerName: '商品ID', // テーブルの列のタイトル
    type: 'number', // テーブルの型
    // formType: 'number', // フォームで使うtype
    width: 90, // テーブルのセルの横幅
    hide: false, // テーブルに表示させるか
    editable: false, // テーブルのセル上で編集させるか
    create: false, // 登録フォームで項目を使用するか
    update: true, // 更新フォームで項目を使用するか
    reauired: true, // 登録フォームで必須項目とするか
    disabled: true, // フォームで入力させない
  },
  {
    field: 'name',
    headerName: '商品名',
    width: 150,
    editable: false,
    create: true,
    update: true,
    reauired: true,
    disabled: false,
  },
  {
    field: 'thickness',
    headerName: '厚み(T)',
    type: 'number',
    formType: 'number',
    width: 100,
    editable: false,
    create: true,
    update: true,
    reauired: true,
    disabled: false,
  },
  {
    field: 'width',
    headerName: '幅(W)',
    type: 'number',
    formType: 'number',
    width: 100,
    editable: false,
    create: true,
    update: true,
    reauired: true,
    disabled: false,
  },
  {
    field: 'length',
    headerName: '長さ(L)',
    type: 'number',
    formType: 'number',
    width: 100,
    editable: false,
    create: true,
    update: true,
    reauired: true,
    disabled: false,
  },
  {
    field: 'a1',
    headerName: '残本数',
    type: 'number',
    formType: 'number',
    width: 100,
  },
  {
    field: 'a2',
    headerName: '残長(物理)',
    type: 'number',
    formType: 'number',
    width: 100,
  },
  {
    field: 'a3',
    headerName: '残長(論理)',
    type: 'number',
    formType: 'number',
    width: 100,
  },
];

// 受注登録の際の商品カラム
export const PRODUCT_TABLE_COLUMNS_FOR_ORDER = [
  {
    field: 'film_products_master_id',
    headerName: '商品ID',
    width: 90,
    editable: true,
    reauired: true,
  },
  {
    field: 'name',
    headerName: '商品名',
    width: 150,
  },
  {
    field: 'order_process',
    headerName: '加工方法',
    width: 110,
    editable: true,
    reauired: true,
    type: 'singleSelect',
    valueOptions: ['カット', '開反', 'その他'],
  },
  {
    field: 'thickness',
    headerName: '厚み(T)',
    type: 'number',
    formType: 'number',
    width: 100,
  },
  {
    field: 'width',
    headerName: '幅(W)',
    type: 'number',
    formType: 'number',
    width: 100,
  },
  {
    field: 'length',
    headerName: '長さ(L)',
    type: 'number',
    formType: 'number',
    width: 100,
    editable: true,
    reauired: true,
    valueParser: (value) => {
      let value_list = value.replace(/^0+/, '').match(/[0-9]{0,3}/g);
      return Number(value_list[0]);
    },
  },
  {
    field: 'order_number',
    headerName: '注文数',
    type: 'number',
    width: 110,
    editable: true,
    reauired: true,
    valueParser: (value) => {
      let value_list = value.replace(/^0+/, '').match(/[0-9]{0,3}/g);
      return Number(value_list[0]);
    },
  },
  {
    field: 'OdNumberLength',
    headerName: '小計長さ',
    width: 110,
    type: 'number',
    valueGetter: (params) => {
      return params.row.length * params.row.order_number;
    },
  },
  {
    field: 'order_house_name',
    headerName: 'ハウス名',
    width: 110,
    editable: true,
  },
  {
    field: 'order_date',
    headerName: '受注日',
    width: 110,
  },
  {
    field: 'delivery_date',
    headerName: '納品日',
    type: 'date',
    width: 150,
    editable: true,
    reauired: true,
    valueFormatter: (params) => {
      let value = moment(params?.value).format('YYYY-MM-DD');
      if (value === 'Invalid date') {
        value = '';
      }
      return value;
    },
  },
  {
    field: 'order_remark',
    headerName: '備考',
    width: 110,
    editable: true,
  },
  {
    field: 'cost_price',
    headerName: '原価',
    type: 'number',
    formType: 'number',
    width: 100,
    editable: true,
    reauired: true,
    isToLocaleString: true,
  },
  {
    field: 'unit_price',
    headerName: '単価',
    type: 'number',
    width: 110,
    editable: true,
    reauired: true,
    isToLocaleString: true,
  },
  {
    field: 'processing_price_value',
    headerName: '加工費',
    type: 'number',
    width: 110,
    editable: true,
    reauired: true,
    isToLocaleString: true,
  },
  {
    field: 'sub_total',
    headerName: '小計',
    type: 'number',
    width: 110,
    editable: true,
    reauired: true,
    isToLocaleString: true,
  },
  {
    field: 'amount_money',
    headerName: '注文金額',
    type: 'number',
    width: 110,
    editable: true,
    reauired: true,
    isToLocaleString: true,
  },
  {
    field: 'specific_gravity',
    headerName: '比重',
    type: 'number',
    formType: 'number',
    width: 100,
  },
  {
    field: 'weight',
    headerName: '重量',
    type: 'number',
    formType: 'number',
    width: 100,
  },
  {
    field: 'total_weight',
    headerName: '合計重量',
    type: 'number',
    formType: 'number',
    width: 100,
  },
  {
    field: 'unit',
    headerName: '単位',
    width: 100,
    editable: true,
  },
  {
    field: 'outsourcing',
    headerName: '外注',
    width: 100,
    type: 'singleSelect',
    editable: true,
    reauired: true,
    valueOptions: ['する', 'しない'],
  },
];

// 在庫登録の際の商品モーダルのカラム
export const PRODUCT_MODAL_COLUMNS_FOR_STOCK = [
  {
    field: 'id',
    headerName: '商品ID',
    type: 'number',
    width: 90,
    hide: false,
    renderCell: (params) => ProductLink(params.row.id, params.value),
  },
  {
    field: 'name',
    headerName: '商品名',
    width: 150,
  },
  {
    field: 'thickness',
    headerName: '厚み(T)',
    type: 'number',
    formType: 'number',
    width: 100,
  },
  {
    field: 'width',
    headerName: '幅(W)',
    type: 'number',
    formType: 'number',
    width: 100,
  },
  {
    field: 'length',
    headerName: '長さ(L)',
    type: 'number',
    formType: 'number',
    width: 100,
  },
  {
    field: 'specific_gravity',
    headerName: '比重',
    type: 'number',
    formType: 'number',
    width: 100,
  },
  {
    field: 'weight',
    headerName: '重量',
    type: 'number',
    formType: 'number',
    width: 100,
  },
  {
    field: 'cost_price',
    headerName: '原価',
    type: 'number',
    formType: 'number',
    width: 100,
  },
  {
    field: 'SellingPriceM1',
    headerName: '販売価格標準',
    type: 'string',
    width: 130,
    editable: false,
    create: false,
    update: false,
  },
];

// 受注登録の際の商品モーダルのカラム
export const PRODUCT_TABLE_MODAL_COLUMNS = [
  {
    field: 'order_number',
    headerName: '注文数',
    width: 90,
    editable: true,
    type: 'number',
    valueParser: (value) => {
      let value_list = value.replace(/^0+/, '').match(/[0-9]{0,3}/g);
      return Number(value_list[0]);
    },
    // デフォルトで1にする
    valueFormatter: (params) => {
      let value = 1;
      // 1以上
      if (params.value > 0) {
        value = params.value;
      }
      return value;
    },
  },
  {
    field: 'id',
    headerName: '商品ID',
    type: 'number',
    width: 90,
    hide: false,
    renderCell: (params) => ProductLink(params.row.id, params.value),
  },
  {
    field: 'name',
    headerName: '商品名',
    width: 150,
  },
  {
    field: 'thickness',
    headerName: '厚み(T)',
    type: 'number',
    formType: 'number',
    width: 100,
  },
  {
    field: 'width',
    headerName: '幅(W)',
    type: 'number',
    formType: 'number',
    width: 100,
  },
  {
    field: 'length',
    headerName: '長さ(L)',
    type: 'number',
    formType: 'number',
    width: 100,
  },
  {
    field: 'specific_gravity',
    headerName: '比重',
    type: 'number',
    formType: 'number',
    width: 100,
  },
  {
    field: 'weight',
    headerName: '重量',
    type: 'number',
    formType: 'number',
    width: 100,
  },
  {
    field: 'cost_price',
    headerName: '原価',
    type: 'number',
    formType: 'number',
    width: 100,
  },
  {
    field: 'SellingPriceM1',
    headerName: '販売価格標準',
    type: 'string',
    width: 130,
    editable: false,
    create: false,
    update: false,
  },
];

export const CLIENT_USAGE_CATEGORY_STATUS_LIST = ['許可', '禁止'];

export const CLIENT_TABLE_COLUMNS = [
  {
    field: 'id',
    headerName: '顧客ID',
    type: 'number',
    // formType: 'number',
    width: 100,
    editable: false,
    create: false,
    update: true,
    reauired: true,
    disabled: true,
  },
  {
    field: 'name',
    headerName: '顧客名',
    width: 150,
    editable: false,
    create: true,
    update: true,
    reauired: true,
    disabled: false,
  },
  {
    field: 'furigana',
    headerName: 'フリガナ',
    width: 150,
    editable: false,
    create: true,
    update: true,
    reauired: true,
    disabled: false,
  },
  {
    field: 'title_of_honor',
    headerName: '敬称',
    width: 80,
    editable: false,
    create: true,
    update: true,
    reauired: true,
    disabled: false,
    formType: 'select',
    valueOptions: ['様', '御中', 'なし'],
  },
  {
    field: 'tel',
    headerName: '電話番号',
    width: 120,
    editable: false,
    create: true,
    update: true,
    reauired: false,
    disabled: false,
  },
  {
    field: 'mobile_tel',
    headerName: '電話番号2',
    width: 120,
    editable: false,
    create: true,
    update: true,
    reauired: false,
    disabled: false,
  },
  {
    field: 'fax',
    headerName: 'fax',
    width: 120,
    editable: false,
    create: true,
    update: true,
    reauired: false,
    disabled: false,
  },
  {
    field: 'mail_address',
    headerName: 'メールアドレス',
    width: 150,
    editable: false,
    create: true,
    update: true,
    reauired: false,
    disabled: false,
  },
  {
    field: 'post_code',
    headerName: '郵便番号',
    width: 120,
    editable: false,
    create: true,
    update: true,
    reauired: false,
    disabled: false,
  },
  {
    field: 'address',
    headerName: '住所',
    width: 150,
    editable: false,
    create: true,
    update: true,
    reauired: false,
    disabled: false,
  },
  {
    field: 'after_address',
    headerName: '住所その他',
    width: 150,
    editable: false,
    create: true,
    update: true,
    reauired: false,
    disabled: false,
  },
  {
    field: 'SalesPriceCategoryName',
    headerName: '販売価格区分',
    width: 150,
    editable: false,
    create: false,
    update: false,
    reauired: false,
    disabled: false,
  },
  {
    field: 'ProcessingPriceCategoryName',
    headerName: '加工費区分',
    width: 150,
    editable: false,
    create: false,
    update: false,
    reauired: false,
    disabled: false,
  },
  {
    field: 'CustomerCategoryName1',
    headerName: '得意先区分1',
    formType: 'select',
    width: 150,
    editable: false,
    create: false,
    update: false,
    reauired: false,
    disabled: false,
  },
  {
    field: 'CustomerCategoryName2',
    headerName: '得意先区分2',
    formType: 'select',
    width: 150,
    editable: false,
    create: false,
    update: false,
    reauired: false,
    disabled: false,
  },
  {
    field: 'CustomerCategoryName3',
    headerName: '得意先区分3',
    formType: 'select',
    width: 150,
    editable: false,
    create: false,
    update: false,
    reauired: false,
    disabled: false,
  },
  {
    field: 'usage_category',
    headerName: '使用区分',
    width: 120,
    editable: false,
    create: true,
    update: true,
    formType: 'select',
    valueOptions: CLIENT_USAGE_CATEGORY_STATUS_LIST,
    defaultValueOption: '許可',
  },
  {
    field: 'remarks',
    headerName: '備考',
    width: 150,
    editable: false,
    create: true,
    update: true,
    reauired: false,
    disabled: false,
  },
];

export const CLIENT_FORM_COLUMNS = [
  {
    field: 'id',
    headerName: '顧客ID',
    type: 'number',
    // formType: 'number',
    width: 100,
    editable: false,
    create: false,
    update: true,
    reauired: true,
    disabled: true,
  },
  {
    field: 'name',
    headerName: '顧客名',
    width: 150,
    editable: false,
    create: true,
    update: true,
    reauired: true,
    disabled: false,
  },
  {
    field: 'furigana',
    headerName: 'フリガナ',
    width: 150,
    editable: false,
    create: true,
    update: true,
    reauired: true,
    disabled: false,
  },
  {
    field: 'title_of_honor',
    headerName: '敬称',
    width: 80,
    editable: false,
    create: true,
    update: true,
    reauired: true,
    disabled: false,
    formType: 'select',
    valueOptions: ['様', '御中', 'なし'],
  },
  {
    field: 'tel',
    headerName: '電話番号',
    width: 120,
    editable: false,
    create: true,
    update: true,
    reauired: false,
    disabled: false,
  },
  {
    field: 'mobile_tel',
    headerName: '電話番号2',
    width: 120,
    editable: false,
    create: true,
    update: true,
    reauired: false,
    disabled: false,
  },
  {
    field: 'fax',
    headerName: 'fax',
    width: 120,
    editable: false,
    create: true,
    update: true,
    reauired: false,
    disabled: false,
  },
  {
    field: 'mail_address',
    headerName: 'メールアドレス',
    width: 150,
    editable: false,
    create: true,
    update: true,
    reauired: false,
    disabled: false,
  },
  {
    field: 'post_code',
    headerName: '郵便番号',
    width: 120,
    editable: false,
    create: true,
    update: true,
    reauired: false,
    disabled: false,
  },
  {
    field: 'address',
    headerName: '住所',
    width: 150,
    editable: false,
    create: true,
    update: true,
    reauired: false,
    disabled: false,
  },
  {
    field: 'after_address',
    headerName: '住所その他',
    width: 150,
    editable: false,
    create: true,
    update: true,
    reauired: false,
    disabled: false,
  },
  {
    field: 'SalesPriceCategoryName',
    headerName: '販売価格区分',
    width: 150,
    editable: false,
    create: false,
    update: false,
    reauired: false,
    disabled: false,
  },
  {
    field: 'film_sales_price_categories_master_id',
    headerName: '販売価格区分',
    formType: 'select',
    width: 150,
    editable: false,
    create: true,
    update: true,
    reauired: true,
    disabled: false,
  },
  {
    field: 'ProcessingPriceCategoryName',
    headerName: '加工費区分',
    width: 150,
    editable: false,
    create: false,
    update: false,
    reauired: false,
    disabled: false,
  },
  {
    field: 'film_processing_price_categories_master_id',
    headerName: '加工費区分',
    formType: 'select',
    width: 150,
    editable: false,
    create: true,
    update: true,
    reauired: true,
    disabled: false,
  },
  {
    field: 'CustomerCategoryName1',
    headerName: '得意先区分1',
    formType: 'select',
    width: 150,
    editable: false,
    create: false,
    update: false,
    reauired: false,
    disabled: false,
  },
  {
    field: 'CustomerCategoryName2',
    headerName: '得意先区分2',
    formType: 'select',
    width: 150,
    editable: false,
    create: false,
    update: false,
    reauired: false,
    disabled: false,
  },
  {
    field: 'CustomerCategoryName3',
    headerName: '得意先区分3',
    formType: 'select',
    width: 150,
    editable: false,
    create: false,
    update: false,
    reauired: false,
    disabled: false,
  },
  {
    field: 'film_customer_categories_1_master_id',
    headerName: '得意先区分1',
    formType: 'select',
    width: 150,
    editable: false,
    create: true,
    update: true,
    reauired: false,
    disabled: false,
  },
  {
    field: 'film_customer_categories_2_master_id',
    headerName: '得意先区分2',
    formType: 'select',
    width: 150,
    editable: false,
    create: true,
    update: true,
    reauired: false,
    disabled: false,
  },
  {
    field: 'film_customer_categories_3_master_id',
    headerName: '得意先区分3',
    formType: 'select',
    width: 150,
    editable: false,
    create: true,
    update: true,
    reauired: false,
    disabled: false,
  },
  {
    field: 'usage_category',
    headerName: '使用区分',
    width: 120,
    editable: false,
    create: true,
    update: true,
    formType: 'select',
    valueOptions: CLIENT_USAGE_CATEGORY_STATUS_LIST,
    defaultValueOption: '許可',
  },
  {
    field: 'remarks',
    headerName: '備考',
    width: 150,
    editable: false,
    create: true,
    update: true,
    reauired: false,
    disabled: false,
  },
];

export const CLIENT_GROUP_COLUMNS = [
  {
    field: 'name',
    headerName: 'グループ名',
    width: 150,
    create: true,
    update: true,
    reauired: true,
    disabled: false,
  },
  {
    field: 'remarks',
    headerName: '備考',
    width: 200,
    create: true,
    update: true,
    reauired: false,
    disabled: false,
    inputRows: 4,
  },
];

export const CUSTOMER_CATEGORY_COLUMNS = [
  {
    field: 'name',
    headerName: '得意先',
    width: 150,
    create: true,
    update: true,
    reauired: true,
    disabled: false,
  },
  {
    field: 'remarks',
    headerName: '備考',
    width: 200,
    create: true,
    update: true,
    reauired: false,
    disabled: false,
    inputRows: 4,
  },
];

// その他設定カラム
export const OTHER_SETTING_COLUMNS = [
  {
    field: 'name',
    headerName: '会社名',
    width: 150,
    create: true,
    update: true,
    reauired: true,
    disabled: false,
  },
  {
    field: 'address',
    headerName: '住所',
    width: 150,
    create: true,
    update: true,
    reauired: true,
    disabled: false,
  },
  {
    field: 'tel1',
    headerName: '電話番号1',
    width: 150,
    create: true,
    update: true,
    reauired: true,
    disabled: false,
  },
  {
    field: 'tel2',
    headerName: '電話番号2',
    width: 150,
    create: true,
    update: true,
    reauired: true,
    disabled: false,
  },
  {
    field: 'delivery_wording',
    headerName: '納品書文言',
    width: 150,
    create: true,
    update: true,
    reauired: true,
    disabled: false,
  },
  {
    field: 'company_overview',
    headerName: '会社概要',
    width: 150,
    create: true,
    update: true,
    reauired: true,
    disabled: false,
  },
  {
    field: 'consumption_tax',
    headerName: '消費税率',
    width: 150,
    type: 'number',
    formType: 'number',
    create: true,
    update: true,
    reauired: true,
    disabled: false,
  },
  {
    field: 'delivery_remind',
    headerName: '納品日期限',
    width: 150,
    type: 'number',
    formType: 'number',
    create: true,
    update: true,
    reauired: true,
    disabled: false,
  },
];

// 受注カラム
export const ORDER_TABLE_COLUMNS = [
  { field: 'id', headerName: '受注ID', type: 'number', width: 90 },
  {
    field: 'film_clients_master_id',
    headerName: '顧客ID',
    type: 'number',
    width: 100,
    create: false,
    update: false,
    reauired: false,
    disabled: false,
    hide: true,
    renderCell: (params) => ClientLink(params.row.film_clients_master_id, params.value),
  },
  {
    field: 'ClientName',
    headerName: '顧客名',
    width: 150,
    create: true,
    update: true,
    reauired: false,
    disabled: false,
  },
  {
    field: 'order_date',
    headerName: '受注日',
    width: 110,
    create: true,
    update: true,
    reauired: false,
    disabled: false,
    renderCell: (params) => (params.value ? format(parseISO(params.value), 'yyyy-MM-dd') : ''),
    filterOperators,
  },
  {
    field: 'delivery_date',
    headerName: '納品日',
    width: 110,
    create: true,
    update: true,
    reauired: false,
    disabled: false,
    renderCell: (params) => (
      <>
        {params.row.IsRed ? (
          <div style={{ color: 'red' }}>{params.value}</div>
        ) : (
          <div>{params.value}</div>
        )}
      </>
    ),
    filterOperators,
  },
  {
    field: 'amount_money',
    headerName: '受注金額',
    type: 'number',
    width: 110,
    create: true,
    update: true,
    reauired: false,
    disabled: false,
    isToLocaleString: true,
  },
];

// 注文一覧カラム
export const SUB_ORDER_TABLE_COLUMNS = [
  {
    field: 'id',
    headerName: '注文ID',
    type: 'number',
    width: 100,
    create: false,
    update: false,
    reauired: false,
    disabled: false,
    renderCell: (params) =>
      subOrderLink(params.row.film_orders_master_id, params.row.id, params.value),
  },
  {
    field: 'film_orders_master_id',
    headerName: '受注ID',
    type: 'number',
    width: 100,
    create: true,
    update: true,
    reauired: false,
    disabled: false,
    renderCell: (params) => orderLink(params.row.film_orders_master_id, params.value),
  },
  {
    field: 'status',
    headerName: 'ステータス',
    width: 120,
    create: true,
    update: true,
    reauired: false,
    disabled: false,
  },
  {
    field: 'film_clients_master_id',
    headerName: '顧客ID',
    type: 'number',
    width: 100,
    renderCell: (params) => ClientLink(params.row.film_clients_master_id, params.value),
  },
  {
    field: 'ClientName',
    headerName: '顧客名',
    width: 150,
  },
  {
    field: 'StockIds',
    headerName: '在庫ID',
    type: 'number',
    width: 150,
  },
  {
    field: 'film_products_master_id',
    headerName: '商品ID',
    type: 'number',
    width: 100,
    create: true,
    update: true,
    reauired: false,
    disabled: false,
    renderCell: (params) => ProductLink(params.row.film_products_master_id, params.value),
  },
  {
    field: 'processing_product_name',
    headerName: '商品名',
    width: 150,
    create: true,
    update: true,
    reauired: false,
    disabled: false,
  },
  {
    field: 'order_thickness',
    headerName: '厚み',
    type: 'number',
    width: 80,
    create: true,
    update: true,
    reauired: false,
    disabled: false,
  },
  {
    field: 'order_width',
    headerName: '幅',
    type: 'number',
    width: 80,
    create: true,
    update: true,
    reauired: false,
    disabled: false,
  },
  {
    field: 'order_length',
    headerName: '長さ',
    type: 'number',
    width: 80,
    create: true,
    update: true,
    reauired: false,
    disabled: false,
  },
  {
    field: 'order_date',
    headerName: '受注日',
    width: 110,
    create: true,
    update: true,
    reauired: false,
    disabled: false,
    filterOperators,
  },
  {
    field: 'delivery_date',
    headerName: '納品日',
    width: 110,
    create: true,
    update: true,
    reauired: false,
    disabled: false,
    renderCell: (params) => (
      <>
        {params.row.IsRed ? (
          <div style={{ color: 'red' }}>{params.value}</div>
        ) : (
          <div>{params.value}</div>
        )}
      </>
    ),
    filterOperators,
  },
  {
    field: 'cost_price',
    headerName: '原価',
    type: 'number',
    width: 100,
    create: true,
    update: true,
    reauired: false,
    disabled: false,
  },
  {
    field: 'unit_price',
    headerName: '単価',
    type: 'number',
    width: 110,
    create: true,
    update: true,
    reauired: false,
    disabled: false,
    isToLocaleString: true,
  },
  {
    field: 'processing_price_value',
    headerName: '加工費',
    type: 'number',
    width: 110,
    create: true,
    update: true,
    reauired: false,
    disabled: false,
    isToLocaleString: true,
  },
  {
    field: 'sub_total',
    headerName: '小計',
    type: 'number',
    width: 110,
    create: true,
    update: true,
    reauired: false,
    disabled: false,
    isToLocaleString: true,
  },
  {
    field: 'order_house_name',
    headerName: 'ハウス名',
    width: 150,
  },
  {
    field: 'sub_order_remark',
    headerName: '備考',
    width: 100,
  },
  {
    field: 'outsourcing',
    headerName: '外注',
    width: 110,
    type: 'singleSelect',
    valueOptions: ['する', 'しない'],
  },
];

// 出荷一覧カラム
export const SHIP_LIST_TABLE_COLUMNS = [
  {
    field: 'film_orders_master_id',
    headerName: '受注ID',
    type: 'number',
    width: 100,
    renderCell: (params) => orderLink(params.row.film_orders_master_id, params.value),
  },
  {
    field: 'id',
    headerName: '注文ID',
    type: 'number',
    width: 100,
    renderCell: (params) =>
      subOrderLink(params.row.film_orders_master_id, params.row.id, params.value),
  },
  {
    field: 'status',
    headerName: 'ステータス',
    width: 120,
  },
  {
    field: 'film_clients_master_id',
    headerName: '顧客ID',
    type: 'number',
    width: 100,
    renderCell: (params) => ClientLink(params.row.film_clients_master_id, params.value),
  },
  {
    field: 'processing_client_name',
    headerName: '顧客名',
    width: 150,
  },
  {
    field: 'film_products_master_id',
    headerName: '商品ID',
    type: 'number',
    width: 100,
    renderCell: (params) => ProductLink(params.row.film_products_master_id, params.value),
  },
  {
    field: 'processing_product_name',
    headerName: '商品名',
    width: 150,
  },
  {
    field: 'order_process',
    headerName: '加工方法',
    width: 120,
  },
  {
    field: 'order_thickness',
    headerName: '厚み',
    type: 'number',
    width: 80,
  },
  {
    field: 'order_width',
    headerName: '幅',
    type: 'number',
    width: 80,
  },
  {
    field: 'order_length',
    headerName: '長さ',
    type: 'number',
    width: 80,
  },
  {
    field: 'order_number',
    headerName: '注文数',
    type: 'number',
    width: 100,
  },
  {
    field: 'unit',
    headerName: '単位',
    width: 80,
  },
  {
    field: 'order_date',
    headerName: '受注日',
    width: 100,
    filterOperators,
  },
  {
    field: 'delivery_date',
    headerName: '納品日',
    width: 100,
    renderCell: (params) => (
      <>
        {params.row.IsRed ? (
          <div style={{ color: 'red' }}>{params.value}</div>
        ) : (
          <div>{params.value}</div>
        )}
      </>
    ),
    filterOperators,
  },
  {
    field: 'unit_price',
    headerName: '単価',
    type: 'number',
    width: 100,
    isToLocaleString: true,
  },
  {
    field: 'amount_money',
    headerName: '注文金額',
    type: 'number',
    width: 120,
    isToLocaleString: true,
  },
  {
    field: 'weight',
    headerName: '重量',
    type: 'number',
    width: 100,
  },
  {
    field: 'total_weight',
    headerName: '合計重量',
    type: 'number',
    width: 120,
  },
  {
    field: 'order_remark',
    headerName: '備考',
    width: 100,
  },
];

// 加工一覧カラム
export const PROCESSING_LIST_TABLE_COLUMNS = [
  {
    field: 'StockIds',
    headerName: '在庫ID',
    type: 'number',
    width: 150,
  },
  {
    field: 'film_orders_master_id',
    headerName: '受注ID',
    type: 'number',
    width: 100,
    renderCell: (params) => orderLink(params.row.film_orders_master_id, params.value),
  },
  {
    field: 'id',
    headerName: '注文ID',
    type: 'number',
    width: 100,
    renderCell: (params) =>
      subOrderLink(params.row.film_orders_master_id, params.row.id, params.value),
  },
  {
    field: 'status',
    headerName: 'ステータス',
    width: 120,
  },
  {
    field: 'film_clients_master_id',
    headerName: '顧客ID',
    type: 'number',
    width: 100,
    renderCell: (params) => ClientLink(params.row.film_clients_master_id, params.value),
  },
  {
    field: 'processing_client_name',
    headerName: '顧客名',
    width: 150,
  },
  {
    field: 'film_products_master_id',
    headerName: '商品ID',
    width: 100,
    type: 'number',
    renderCell: (params) => ProductLink(params.row.film_products_master_id, params.value),
  },
  {
    field: 'processing_product_name',
    headerName: '商品名',
    width: 150,
  },
  {
    field: 'order_process',
    headerName: '加工方法',
    width: 120,
  },
  {
    field: 'order_thickness',
    headerName: '厚み',
    type: 'number',
    width: 80,
  },
  {
    field: 'order_width',
    headerName: '幅',
    type: 'number',
    width: 80,
  },
  {
    field: 'order_length',
    headerName: '長さ',
    type: 'number',
    width: 80,
  },
  {
    field: 'weight',
    headerName: '重量',
    type: 'number',
    width: 80,
  },
  {
    field: 'order_date',
    headerName: '受注日',
    width: 100,
    filterOperators,
  },
  {
    field: 'delivery_date',
    headerName: '納品日',
    width: 100,
    renderCell: (params) => (
      <>
        {params.row.IsRed ? (
          <div style={{ color: 'red' }}>{params.value}</div>
        ) : (
          <div>{params.value}</div>
        )}
      </>
    ),
    filterOperators,
  },
  {
    field: 'order_house_name',
    headerName: 'ハウス名',
    width: 150,
  },
  {
    field: 'order_remark',
    headerName: '備考',
    width: 100,
  },
];

// 受注詳細注文カラム
export const SUB_ORDER_TABLE_COLUMNS_FOR_ORDER = [
  {
    field: 'StockIds',
    headerName: '在庫ID',
    type: 'number',
    width: 150,
  },
  {
    field: 'film_products_master_id',
    headerName: '商品ID',
    type: 'number',
    width: 110,
  },
  {
    field: 'processing_product_name',
    headerName: '商品名',
    width: 150,
  },
  {
    field: 'order_process',
    headerName: '加工方法',
    width: 110,
    editable: true,
    reauired: true,
    type: 'singleSelect',
    valueOptions: ['カット', '開反', 'その他'],
  },
  {
    field: 'order_thickness',
    headerName: '厚み(T)',
    type: 'number',
    formType: 'number',
    width: 100,
  },
  {
    field: 'order_width',
    headerName: '幅(W)',
    type: 'number',
    formType: 'number',
    width: 100,
  },
  {
    field: 'order_length',
    headerName: '長さ(L)',
    type: 'number',
    formType: 'number',
    width: 100,
    editable: true,
    reauired: true,
    valueParser: (value) => {
      let value_list = value.replace(/^0+/, '').match(/[0-9]{0,3}/g);
      return Number(value_list[0]);
    },
  },
  {
    field: 'order_number',
    headerName: '注文数',
    type: 'number',
    width: 110,
    // editable: true,
  },
  {
    field: 'OdNumberLength',
    headerName: '小計長さ',
    width: 110,
    type: 'number',
    valueGetter: (params) => {
      return params.row.order_length * params.row.order_number;
    },
  },
  {
    field: 'order_date',
    headerName: '受注日',
    width: 110,
  },
  {
    field: 'delivery_date',
    headerName: '納品日',
    type: 'date',
    width: 150,
    editable: true,
    reauired: true,
    valueFormatter: (params) => {
      let value = moment(params?.value).format('YYYY-MM-DD');
      if (value === 'Invalid date') {
        value = '';
      }
      return value;
    },
  },
  {
    field: 'order_remark',
    headerName: '備考',
    width: 110,
    editable: true,
  },
  {
    field: 'cost_price',
    headerName: '原価',
    type: 'number',
    formType: 'number',
    width: 100,
    editable: true,
    reauired: true,
    isToLocaleString: true,
  },
  {
    field: 'unit_price',
    headerName: '単価',
    type: 'number',
    width: 100,
    editable: true,
    reauired: true,
    isToLocaleString: true,
  },
  {
    field: 'processing_price_value',
    headerName: '加工費',
    type: 'number',
    editable: true,
    reauired: true,
    width: 100,
    isToLocaleString: true,
  },
  {
    field: 'sub_total',
    headerName: '小計',
    type: 'number',
    editable: true,
    reauired: true,
    width: 100,
    isToLocaleString: true,
  },
  {
    field: 'amount_money',
    headerName: '注文金額',
    type: 'number',
    width: 110,
    editable: true,
    reauired: true,
    isToLocaleString: true,
  },
  {
    field: 'specific_gravity',
    headerName: '比重',
    type: 'number',
    formType: 'number',
    width: 100,
  },
  {
    field: 'weight',
    headerName: '重量',
    type: 'number',
    formType: 'number',
    width: 100,
  },
  {
    field: 'total_weight',
    headerName: '合計重量',
    type: 'number',
    formType: 'number',
    width: 110,
  },
  {
    field: 'unit',
    headerName: '単位',
    width: 100,
    editable: true,
  },
  {
    field: 'OutsourcingIds',
    headerName: '外注ID',
    type: 'number',
    width: 150,
  },
];

// 注文のステータス
export const SUB_ORDER_STATUS_LIST = [
  '新規',
  '加工指示書出力済',
  '在庫割当済',
  '加工作業中',
  '加工済',
  '出荷済',
];

// 受注詳細注文モーダルカラム
export const SUB_ORDER_MODAL_TABLE_COLUMNS_FOR_ORDER = [
  {
    field: 'id',
    headerName: '注文ID',
    type: 'number',
    width: 100,
  },
  {
    field: 'film_orders_master_id',
    headerName: '受注ID',
    type: 'number',
    width: 100,
  },
  {
    field: 'film_products_master_id',
    headerName: '商品ID',
    width: 110,
  },
  {
    field: 'processing_product_name',
    headerName: '商品名',
    width: 150,
  },
  {
    field: 'order_thickness',
    headerName: '厚み',
    type: 'number',
    width: 80,
  },
  {
    field: 'order_width',
    headerName: '幅',
    type: 'number',
    width: 80,
  },
  {
    field: 'order_length',
    headerName: '長さ',
    type: 'number',
    width: 80,
    reauired: true,
    renderCell: (params) => (
      // ステータスが加工作業中(3)以上の注文は編集不可
      <>
        {params.row.StatusCode >= 3 ? (
          <div className='uneditable'>{params.value}</div>
        ) : (
          <div>{params.value}</div>
        )}
      </>
    ),
  },
  {
    field: 'order_process',
    headerName: '加工方法',
    width: 110,
    type: 'singleSelect',
    valueOptions: ['カット', '開反', 'その他'],
    renderCell: (params) => (
      // ステータスが加工作業中(3)以上の注文は編集不可
      <>
        {params.row.StatusCode >= 3 ? (
          <div className='uneditable'>{params.value}</div>
        ) : (
          <div>{params.value}</div>
        )}
      </>
    ),
  },
  {
    field: 'delivery_date',
    headerName: '納品日',
    width: 100,
  },
  {
    field: 'status',
    headerName: 'ステータス',
    width: 120,
    editable: true,
    type: 'singleSelect',
    valueOptions: SUB_ORDER_STATUS_LIST,
  },
  {
    field: 'order_house_name',
    headerName: 'ハウス名',
    width: 150,
    editable: true,
    renderCell: (params) => (
      // ステータスが加工作業中(3)以上の注文は編集不可
      <>
        {params.row.StatusCode >= 3 ? (
          <div className='uneditable'>{params.value}</div>
        ) : (
          <div>{params.value}</div>
        )}
      </>
    ),
  },
  {
    field: 'sub_order_remark',
    headerName: '備考',
    width: 150,
    editable: true,
    renderCell: (params) => (
      // ステータスが加工作業中(3)以上の注文は編集不可
      <>
        {params.row.StatusCode >= 3 ? (
          <div className='uneditable'>{params.value}</div>
        ) : (
          <div>{params.value}</div>
        )}
      </>
    ),
  },
  {
    field: 'outsourcing',
    headerName: '外注',
    width: 110,
    type: 'singleSelect',
    editable: true,
    //reauired: true,
    valueOptions: ['する', 'しない'],
    renderCell: (params) => (
      // ステータスが出荷済(5)以上の注文は編集不可
      <>
        {params.row.StatusCode >= 5 ? (
          <div className='uneditable'>{params.value}</div>
        ) : (
          <div>{params.value}</div>
        )}
      </>
    ),
  },
];

export const STOCK_TABLE_COLUMNS = [
  {
    field: 'id',
    headerName: '在庫ID',
    width: 100,
    type: 'number',
    create: false,
    update: true,
    reauired: false,
    disabled: true,
  },
  {
    field: 'film_products_master_id',
    headerName: '商品ID',
    width: 100,
    type: 'number',
    create: false,
    update: true,
    reauired: false,
    disabled: true,
    renderCell: (params) => ProductLink(params.row.film_products_master_id, params.value),
  },
  {
    field: 'ProductName',
    headerName: '商品名',
    width: 150,
    create: false,
    update: false,
    reauired: false,
    disabled: true,
  },
  {
    field: 'thickness',
    headerName: '厚み',
    type: 'number',
    width: 80,
    create: false,
    update: false,
    reauired: false,
    disabled: false,
  },
  {
    field: 'width',
    headerName: '幅',
    type: 'number',
    width: 80,
    create: false,
    update: false,
    reauired: false,
    disabled: false,
  },
  {
    field: 'length',
    headerName: '長さ',
    type: 'number',
    width: 80,
    create: false,
    update: true,
    reauired: false,
    disabled: true,
  },
  {
    field: 'length_ph',
    headerName: '残長(物理)',
    width: 120,
    formType: 'number',
    create: false,
    update: true,
    reauired: false,
    disabled: false,
  },
  {
    field: 'length_lo',
    headerName: '残長(論理)',
    width: 120,
    formType: 'number',
    create: false,
    update: true,
    reauired: false,
    disabled: true,
  },
  {
    field: 'stocking_date',
    headerName: '仕入れ日',
    width: 120,
    create: true,
    update: true,
    reauired: false,
    disabled: false,
    formType: 'date',
    filterOperators,
  },
  {
    field: 'cost_price',
    headerName: '原価',
    width: 100,
    formType: 'number',
    create: false,
    update: true,
    reauired: false,
    disabled: false,
  },
  {
    field: 'status',
    headerName: '状態',
    width: 120,
    create: true,
    update: true,
    reauired: false,
    disabled: true,
    formType: 'select',
    valueOptions: ['原反(新品)', '仕掛品', 'その他', '廃棄'], // 登録用
    valueOptions2: ['原反(新品)', '原反(残反)', '仕掛品', 'その他', '廃棄'], // 詳細用
  },
  {
    field: 'remarks',
    headerName: '備考',
    width: 150,
    create: true,
    update: true,
    reauired: false,
    disabled: false,
  },
];

// 在庫選択モーダル用
export const STOCK_TABLE_COLUMNS_FOR_MODAL = [
  {
    field: 'stocking_date',
    headerName: '仕入れ日',
    width: 100,
  },
  {
    field: 'id',
    headerName: '在庫ID',
    type: 'number',
    width: 100,
  },
  {
    field: 'film_products_master_id',
    headerName: '商品ID',
    type: 'number',
    width: 100,
    renderCell: (params) => ProductLink(params.row.film_products_master_id, params.value),
  },
  {
    field: 'ProductName',
    headerName: '商品名',
    width: 200,
  },
  {
    field: 'thickness',
    headerName: '厚み(T)',
    type: 'number',
    width: 100,
  },
  {
    field: 'width',
    headerName: '幅(W)',
    type: 'number',
    width: 100,
  },
  {
    field: 'length',
    headerName: '長さ(L)',
    type: 'number',
    width: 100,
  },
  {
    field: 'length_ph',
    headerName: '残長(物理)',
    type: 'number',
    width: 100,
  },
  {
    field: 'length_lo',
    headerName: '残長(論理)',
    type: 'number',
    width: 100,
  },
  {
    field: 'status',
    headerName: '状態',
    width: 100,
  },
];

// 在庫履歴
export const STOCK_HISTORY_TABLE_COLUMNS = [
  {
    field: 'id',
    headerName: '在庫履歴ID',
    type: 'number',
    width: 150,
  },
  {
    field: 'film_stocks_master_id',
    headerName: '在庫ID',
    type: 'number',
    width: 100,
  },
  {
    field: 'film_products_master_id',
    headerName: '商品ID',
    type: 'number',
    width: 100,
    renderCell: (params) => ProductLink(params.row.film_products_master_id, params.value),
  },
  {
    field: 'film_sub_orders_master_id',
    headerName: '注文ID',
    type: 'number',
    width: 100,
    renderCell: (params) =>
      subOrderLink(
        params.row.film_orders_master_id,
        params.row.film_sub_orders_master_id,
        params.value
      ),
  },
  {
    field: 'film_orders_master_id',
    headerName: '受注ID',
    type: 'number',
    width: 100,
    renderCell: (params) => orderLink(params.row.film_orders_master_id, params.value),
  },
  {
    field: 'history_date',
    headerName: '変更日時',
    width: 120,
  },
  {
    field: 'length_us',
    headerName: '使用長',
    type: 'number',
    width: 100,
  },
  {
    field: 'length_ph',
    headerName: '残長(物理)',
    type: 'number',
    width: 120,
  },
  {
    field: 'length_lo',
    headerName: '残長(論理)',
    type: 'number',
    width: 120,
  },
  {
    field: 'reason',
    headerName: '変更事由',
    width: 150,
  },
];
