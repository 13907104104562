/*
    フィルタで使用するDatePicker。
    既存CustomDatePickerの動きを変更しないようにするため新設。
    https://redmine.midori-cloud.net/issues/31919

    そもそもsetDateしないで値はそのままにしたい。
*/
import React from 'react';
import PropTypes from 'prop-types';
// MUI
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import TextField from '@mui/material/TextField';

export const FilterDatePicker = (props) => {
  const {
    date,
    setDate,
    isDisabled = false,
    addStyle = {},
    params,
    getRow,
    cellEditStart,
    setIsDisableFlag,
  } = props;

  // 日付から文字列に変換する関数
  const getStringFromDate = (date) => {
    if (!date) {
      return '';
    }
    var year_str = date.getFullYear();
    // 月だけ+1すること
    var month_str = 1 + date.getMonth();
    var day_str = date.getDate();
    // 0埋めする
    month_str = ('0' + month_str).slice(-2);
    day_str = ('0' + day_str).slice(-2);

    let format_str = 'YYYY-MM-DD';
    format_str = format_str.replace(/YYYY/g, year_str);
    format_str = format_str.replace(/MM/g, month_str);
    format_str = format_str.replace(/DD/g, day_str);
    return format_str;
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        disabled={isDisabled}
        value={date ?? null}
        label=''
        inputFormat='yyyy-MM-dd'
        mask='____-__-__'
        onChange={(newValue) => {
          if (setIsDisableFlag != undefined) {
            setIsDisableFlag(false);
          }
          if (!Number.isNaN(newValue.getTime())) {
            setDate(getStringFromDate(newValue));
          }
          if (getRow) {
            getRow(params.row);
          }
          cellEditStart && cellEditStart(params);
        }}
        renderInput={(params) => (
          <TextField
            sx={{
              ...{
                width: '100%',
              },
              ...addStyle,
            }}
            {...params}
            size='small'
          />
        )}
      />
    </LocalizationProvider>
  );
};

FilterDatePicker.propTypes = {
  date: PropTypes.string,
  setDate: PropTypes.func,
  isDisabled: PropTypes.bool,
  addStyle: PropTypes.object,
  params: PropTypes.object,
  getRow: PropTypes.func,
  cellEditStart: PropTypes.func,
  setIsDisableFlag: PropTypes.func,
};
